import NavBar from '@/components/NavBar/NavBar.vue';

export default {
    name: 'InstantVerify',

    components: {
        NavBar
    },
    data: () => ({
        active_services: 0, 
        passed_verifications: 0,
        failed_verifications: 0, 
     }),

     methods: {
        get_instant_verify_dashboard_data() {
          const TOKEN = localStorage.getItem('token');
          const GET_DASHBOARD_DATA_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/instant_verify_dashboard/`;
    
          $.ajax({
            url: GET_DASHBOARD_DATA_API,
            method: 'GET',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`,
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            },
            success: (response) => {
                Swal.close();
                this.active_services = response.active_services;
                this.passed_verifications = response.successful_services;
                this.failed_verifications = response.failed_services;
    
            },
            error: () => {
              Swal.close();
            }
          });
        },
      },
    
      mounted() {
        this.get_instant_verify_dashboard_data();
      }

}



