
<form  method="post" @submit.prevent="login">
    <div class="text-center">
        <p class="text-dark fw-bolder mb-3 text-x">Login</p>
    </div>

    <div class="form-group mb-3">
        <input 
            type="text" 
            class="form-control rounded text-xs" 
            placeholder="Enter Email"  
            v-model="username" 
            name="username"
            >
            <small class="text-danger p-2 text-xxs fw-bold d-none username-message">Please fill out this field</small>
    </div> 

    <div class="form-group mb-3">
        <input 
            type="password" 
            class="form-control rounded text-xs" 
            placeholder="Enter Password" 
            v-model="password" 
            name="password" 
            >
            <small class="text-danger p-2 text-xxs fw-bold d-none password-message">Please fill out this field</small>
    </div>

    <div v-for="(field, index) in dynamic_fields" :key="index" class="form-group mb-2">
        <!-- Text Input -->
        <input
          v-if="field.has_text"
          type="text"
          class="form-control form-control-sm rounded text-xs w-100"
          v-model="field.value"
          :placeholder="field.question"
          :name="field.question"
        />
      
        <!-- Number Input -->
        <input
          v-if="field.has_number"
          type="number"
          class="form-control form-control-sm rounded text-xs w-100"
          v-model="field.value"
          :placeholder="field.question"
          :name="field.question"
        />
      
        <!-- Checkbox Options -->
        <div v-if="field.has_checkbox">
          <label class="text-xs">{{ field.question }}</label>
          <div v-for="option in field.options" :key="option">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="field.value"
              :value="option"
              :id="`checkbox_${index}_${option}`"
            />
            <label :for="`checkbox_${index}_${option}`" class="form-check-label text-xs form-control-sm">
              {{ option }}
            </label>
          </div>
        </div>
      
        <!-- Dropdown for Selections -->
        <div v-if="field.has_selection">
          <label class="text-xs">{{ field.question }}</label>
          <select
            class="form-control form-control-sm rounded text-xs w-100"
            v-model="field.value"
          >
            <option value="" disabled>Select an option</option>
            <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
          </select>
        </div>
      
        <!-- File Input -->
        <div v-if="field.has_file">
          <label class="text-xs">{{ field.question }}</label>
          <input
            type="file"
            class="form-control form-control-sm rounded text-xs w-100"
            @change="handle_file_upload($event, index)"
          />
        </div>
      
        <!-- Date Input -->
        <div v-if="field.has_date">
          <label class="text-xs">{{ field.question }}</label>
          <input
            type="date"
            class="form-control form-control-sm rounded text-xs w-100"
            v-model="field.value"
            :placeholder="field.question"
            :name="field.question"
          />
        </div>
      
        <!-- Error Messages -->
        <small v-if="field.errorMessage" class="text-danger p-2 text-xxs fw-bold">{{ field.errorMessage }}</small>
    </div>
	
    <small class="text-danger p-2 text-xxs fw-bold d-none login-message"></small>
    <div class="mb-3">
        <button type="submit" class="btn btn-lg bg-green rounded text-xs w-75 fw-bold mt-2" @click="route_to">Login</button>
    </div>

    <div class="mt-1 text-center">
        <span class="text-dark text-xs">Not Registered?</span>
        <a class="text-primary text-xs"><router-link to="/user_registration"> Register here</router-link></a>
    </div>
    <div class="text-center">
        <span class="text-dark text-xs">Forgot password?</span>
        <a :href="reset_url" class="text-mute text-xs"> Reset password</a>
    </div>
</form>