import { createStore } from 'vuex';
import router from './router'; // Adjust the path based on your directory structure

const store = createStore({
  state: {
    isRegistered: false,
    project_id: localStorage.getItem('project_id') || null,
    workflow_id: localStorage.getItem('workflow_id') || null,
    category_id: localStorage.getItem('category_id') || null,
    client_id: localStorage.getItem('client_id') || null,
    service_id: localStorage.getItem('service_id') || null,
  },
  mutations: {
    setUserRegistered(state) {
      state.isRegistered = true;
    },
    set_project_id(state, project_id) {
      state.project_id = project_id;
      localStorage.setItem('project_id', project_id);
    },
    set_workflow_id(state, workflow_id) {
      state.workflow_id = workflow_id;
      localStorage.setItem('workflow_id', workflow_id);
    },
    set_category_id(state, category_id) {
      state.category_id = category_id;
      localStorage.setItem('category_id', category_id);
    },
    set_client_id(state, client_id) {
      state.client_id = client_id;
      localStorage.setItem('client_id', client_id);
    },
    set_service_id(state, service_id) {
      state.service_id = service_id;
      localStorage.setItem('service_id', service_id);
    }
  },
  actions: {
    registerUser({ commit }) {
      commit('setUserRegistered');
      router.push({ name: 'user_otp' });
    },
    update_project_id({ commit }, project_id) {
      commit('set_project_id', project_id);
    },
    update_workflow_id({ commit }, workflow_id) {
      commit('set_workflow_id', workflow_id);
    },
    update_category_id({ commit }, category_id) {
      commit('set_category_id', category_id);
    },
    update_client_id({ commit }, client_id) {
      commit('set_client_id', client_id);
    },
    update_service_id({ commit }, service_id) {
      commit('set_service_id', service_id);
    }
  },
  getters: {
    isRegistered: state => state.isRegistered,
    get_project_id: state => state.project_id,
    get_workflow_id: state => state.workflow_id,
    get_category_id: state => state.category_id,
    get_client_id: state => state.client_id,
    get_service_id: state => state.service_id,
  }
});

export default store;

