<div class="text-center">
    <p class="text-dark fw-bold mb-2 text-x">Facial Biometrics Verification</p>
    <small class="text-danger mb-2 fw-bold">
        <i class="fa fa-info-circle me-1"></i>
        Please make sure you have your camera enabled!
    </small>

    <div class="form-group mt-2">
        <input
            type="text"
            class="form-control rounded text-xs"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            v-model="client_id"
            disabled
        />
    </div>

    <div class="row mt-4">
        <div class="col-12 col-md-6 mb-3" id="take_photo">
            <figure class="content h-75 position-relative">
                <video ref="video" id="video" class="invert mb-2 position-relative" autoplay loop muted playsinline></video>
                <div class="circle-overlay"></div>
            </figure>
            <button @click="take_photo" class="btn bg-green rounded text-xs mb-2 custom w-75 fw-bold shadow">
                {{ photo_taken ? 'Retake' : 'Take Photo' }}
            </button>
        </div>

        <div class="col-12 col-md-6 mb-3" id="submit_photo">
            <figure class="content h-75 position-relative">
                <input v-model="photo_src" name="src" type="text" class="d-none" />
                <canvas ref="canvas" id="canvas" class="mb-2 position-relative"></canvas>
            </figure>
            <button @click="retake_photo" type="submit" class="btn bg-green rounded text-xs mb-2 custom w-75 fw-bold shadow d-none retake-photo">
                Retake Photo
            </button>
            <button @click="submit_photo" type="submit" class="btn bg-green rounded text-xs mb-2 custom w-75 fw-bold shadow" :disabled="!photo_taken">
                Submit
            </button>
        </div>
    </div>
</div>

<!-- Create Modal -->
<div class="modal fade" id="show_instruction" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"  aria-hidden="true"  @click="retake_photo">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    <ol class="breadcrumb justify-content-center rounded-pill text-white w-100">
                      <li class="breadcrumb-item">Please read the below instructions carefully...</li>
                    </ol>
                </div>
                <div class="text-center">
                    <p class="text-darkblue text-xs"><i class='bx bx-glasses me-2'></i>Remove any <span class="fw-bold">hats, sunglasses, or eyewear</span></p>
                    <p class="text-darkblue text-xs"><i class='fa fa-eye me-2'></i>Make sure you are looking at the camera</p>
                    <p class="text-danger text-xs">Move your face closer to the camera, make sure your neck is not in the circle</p>
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                      <button type="button" class="btn btn-danger btn-block rounded-pill custom text-capitalize text-x" data-bs-dismiss="modal" id="closeModalBtn">Let's go</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>