<div class="container d-flex justify-content-center align-items-center min-vh-100">
	<div class="card shadow border-2 custom-border rounded-3 p-4 w-50">
	  <div class="text-center">
		<p class="text-dark text-s fw-bolder mb-4 fs-5">Income Estimator</p>
	  </div>

	  <div class="mb-3">
		<label for="company_name" class="form-label fw-bold text-xs">ID Number <span class="text-danger fw-bold">*</span></label>
		<div class="col">
		  <input 
			id="id_number"
			minlength="13"
			maxlength="13"
			class="form-control text-xs rounded-small p-3 shadow-sm"
			v-model="id_number" 
			placeholder="Enter an SA ID Number"
		  >
		  <small class="text-danger p-2 text-xxs fw-bold d-none id-message">Please fill out this field</small>
		  <small class="text-danger p-2 text-xxs fw-bold d-none invalid-message"> Please enter a valid ID number</small>
		  <small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>
		</div>
	  </div>

	  <div class="text-end">
		<button 
		  type="submit" 
		  class="btn bg-green btn-sm mt-1 px-4 rounded-small shadow-sm fw-bold text-dark text-xs"
		  @click="submit_id"
		>
		  Submit
		</button>   
	  </div>
	</div>
</div>