export default {
  name: 'AntiMoneyLaundering',
  data: () => ({ 
    first_name: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
    country_code: '',
    error: '',
    json_data: {}
    }),
  computed: {
    formatted_data() {
      return JSON.stringify(this.json_data, null, 2); // Format JSON with indentation
    }
    },
  methods: {



    anti_money_laundering_application_api() {
      const TOKEN = localStorage.getItem('token');
      const ANTI_MONEY_LAUNDERING_APPLICATION_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/anti_money_laundering_application/`;

      this.error = '';

      $.ajax({
        url: ANTI_MONEY_LAUNDERING_APPLICATION_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
            first_name: this.first_name,
            last_name: this.last_name,
            date_of_birth: this.date_of_birth
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
            const application_id = response.application_id;
            this.anti_money_laundering_api(application_id);
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
            $('.error-message').text(error_message).removeClass('d-none');
                setTimeout(() => {
                    $('.error-message').text('').addClass('d-none');
                }, 3000);
        }else {
            $('.error-message').text(error_message).removeClass('d-none');
                setTimeout(() => {
                    $('.error-message').text('').addClass('d-none');
                }, 3000);
          }
        }
      });
    },
    anti_money_laundering_api(application_id) {
        const TOKEN = localStorage.getItem('token');
        const ANTI_MONEY_LAUNDERING_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/anti_money_laundering/`;
  
        this.error = '';
  
        $.ajax({
          url: ANTI_MONEY_LAUNDERING_API,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify({
                application_id: application_id,
                first_name: this.first_name,
                last_name: this.last_name,
                date_of_birth: this.date_of_birth,
                gender: this.gender,
                country_code: this.country_code
          }),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => Swal.showLoading(),
            });
          },
          success: (response) => {
            Swal.close();
            Swal.fire({
            icon: 'success',
            title: 'Successful',
            confirmButtonText: 'OK'
            })
            this.json_data = response.result;
            $('#verification_results').modal('show');
          },
          error: (error) => {
            Swal.close();
            let error_message = 'Something went wrong!';
  
            if (error.responseJSON && error.responseJSON.message) {
              error_message = error.responseJSON.message;
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
          }else {
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
            }
          }
        });
    }
  }
};