<div class="container-fluid">
  <div v-if="loading" class="text-center mt-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else>
    <div class="row mt-2 mb-5 mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 p-2 mt-2">
          <div class="card-body">
            <h4 class="fw-bold text-dark text-s mb-4">Assigned Workflows</h4>
            <div class="table">
              <table id="workflows-table" class="table-responsive w-100">
                <thead class="bg-green text-dark">
                  <tr>
                    <th class="text-uppercase bg-green text-xs font-weight-bolder opacity-7 text-start">ID</th>
                    <th class="text-uppercase bg-green text-xs font-weight-bolder opacity-7">Workflow Name</th>
                    <th class="text-uppercase bg-green text-xs font-weight-bolder opacity-7 text-start">Company Name</th>
                    <th class="text-uppercase bg-green text-xs font-weight-bolder opacity-7 text-start">Date Created</th>
                    <th class="text-uppercase bg-green text-xs font-weight-bolder opacity-7 text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(workflow, index) in workflows" :key="workflow.id">
                    <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                    <td class="text-xs fw-bold">{{ workflow.name }}</td>
                    <td class="text-xs fw-bold text-start">{{ workflow.company_name }}</td>
                    <td class="text-xs fw-bold text-start">{{ format_date(workflow.date_created) }}</td>
                    <td>
                      <button type="button" class="btn bg-dark rounded-pill fw-bold btn-sm text-xs text-white me-2" @click="onboard_workflow(workflow.workflow_id)">Onboard</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  