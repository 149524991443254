<div class="container-fluid">
  <div ref="services_container">
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4">
          <div class="card-header bg-dark text-center p-2">
            <h6 class="card-heading fw-bold text-white text-s">Selected Services in Your Workflow</h6>
          </div>

          <div class="card-body">
            <table id="order-service-table" class="table table-responsive w-100">
              <thead class="bg-gray">
                <tr>                  
                  <th class="text-uppercase text-xs font-weight-bolder opacity-7">Service Name</th>
                  <th class="text-uppercase text-xs font-weight-bolder opacity-7">Order Service</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(service, index) in sorted_services" :key="service.service_id">
                  <td class="text-xs fw-bold">{{ service.service_name }}</td>
                  <td>
                    <!-- Button to Open Modal -->
                    <button class="btn btn-sm bg-green rounded-pill text-dark fw-bold rounded" type="button" @click="open_modal(service)">
                      <i class="bi bi-list"></i> {{ service.selected_order ? service.selected_order : 'Select Order' }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12">
              <div align="right" class="p-2">
                <button type="button" class="btn bg-green rounded-pill text-xs fw-bold" @click="route_to">Confirm Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Structure -->
  <div v-if="selectedService" id="yourModal" class="modal fade show order-service-modal" tabindex="-1" aria-labelledby="modalTitle" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitle">Order Numbering</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="close_modal"></button>
        </div>
        <div class="modal-body">
          <!-- Dropdown List for Numbers -->
          <div class="form-group">
            <label for="orderSelect">Select Order</label>
            <div class="input-group">
              <select v-model="selectedOrder" id="orderSelect" class="form-select">
                <option v-for="n in maxOrder" :key="n" :value="n">{{ n }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-green rounded-pill text-xs fw-bold" @click="save_order">Update</button>
        </div>
      </div>
    </div>
  </div>
</div>