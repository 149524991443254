<form method="post" @submit.prevent="submit_otp">
	<div class="text-center">
		<P class="text-dark fw-bold mb-3 text-x">OTP</P>
	</div>
	
	<div class="form-group">
			<input type="text"
			class="form-control rounded text-xs" 
			v-model="otp"
			maxlength="5"
			minlength="5" 
			name="pin"
			placeholder="Enter OTP"
			@input="validate_input('otp')"
			>
			<small class="text-danger p-2 text-xxs fw-bold d-none otp-message">Please fill out this field</small>
			<small v-if="show_resend_message" class="text-success p-2 text-xxs fw-bold">OTP has been resent</small>
	        <small v-if="failed_resend_message" class="text-danger p-2 text-xxs fw-bold">Failed to resend otp</small>
    </div>
	<small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>

	<div v-for="(field, index) in dynamic_fields" :key="index" class="form-group mb-2">
		<!-- Text Input -->
		<input
		  v-if="field.has_text"
		  type="text"
		  class="form-control form-control-sm rounded text-xs w-100"
		  v-model="field.value"
		  :placeholder="field.question"
		  :name="field.question"
		/>
	  
		<!-- Number Input -->
		<input
		  v-if="field.has_number"
		  type="number"
		  class="form-control form-control-sm rounded text-xs w-100"
		  v-model="field.value"
		  :placeholder="field.question"
		  :name="field.question"
		/>
	  
		<!-- Checkbox Options -->
		<div v-if="field.has_checkbox">
		  <label class="text-xs">{{ field.question }}</label>
		  <div v-for="option in field.options" :key="option">
			<input
			  type="checkbox"
			  class="form-check-input"
			  v-model="field.value"
			  :value="option"
			  :id="`checkbox_${index}_${option}`"
			/>
			<label :for="`checkbox_${index}_${option}`" class="form-check-label text-xs form-control-sm">
			  {{ option }}
			</label>
		  </div>
		</div>
	  
		<!-- Dropdown for Selections -->
		<div v-if="field.has_selection">
		  <label class="text-xs">{{ field.question }}</label>
		  <select
			class="form-control form-control-sm rounded text-xs w-100"
			v-model="field.value"
		  >
			<option value="" disabled>Select an option</option>
			<option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
		  </select>
		</div>
	  
		<!-- File Input -->
		<div v-if="field.has_file">
		  <label class="text-xs">{{ field.question }}</label>
		  <input
			type="file"
			class="form-control form-control-sm rounded text-xs w-100"
			@change="handle_file_upload($event, index)"
		  />
		</div>
	  
		<!-- Date Input -->
		<div v-if="field.has_date">
		  <label class="text-xs">{{ field.question }}</label>
		  <input
			type="date"
			class="form-control form-control-sm rounded text-xs w-100"
			v-model="field.value"
			:placeholder="field.question"
			:name="field.question"
		  />
		</div>
	  
		<!-- Error Messages -->
		<small v-if="field.errorMessage" class="text-danger p-2 text-xxs fw-bold">{{ field.errorMessage }}</small>
	</div>

	<div class="mt-3 mb-2">
		<span class="text-xs">Did not receive OTP? 
			<a class="text-primary text-xs otp" @click="resend_otp">Resend OTP</a></span>
	</div>
	
	<div class="col-md">
		<button type="submit" class="btn bg-green btn-lg shadow-lg rounded text-xs fs-6 mt-2 w-75 fw-bold" @click="route_to">Continue</button>
	</div>
</form>