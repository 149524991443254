export default {
  name: 'ConfirmWorkflow',

  methods:{

    redirect_to_view_customers(){
      this.$router.push({
        name: 'view_customers',
      });
    }
  }

};