export default {
  name: 'FacialBiometrics',
  data: () => ({
    client_id: '',
    photo_taken: false,
    photo_src: '',
    video_stream: null,
    is_mobile_view: false
  }),

  methods: {
    get_id_number() {
      const TOKEN = localStorage.getItem('token');
      const GET_ID_URL = `${process.env.API_BASE_URL}/verify_api/verify_company_api/get_facial_biometric_information/`;
      $.ajax({
        url: GET_ID_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          if (response.id_number_exists && response.user_profile_data && response.user_profile_data.length > 0) {
            var id_number = response.user_profile_data[0].id_number;
            this.client_id = id_number;
          } else {
            console.error('ID number not found in response');
          }
        },
        error: (error) => {
          console.error('Error retrieving ID number:', error);
        }
      });
    },
    initialize_camera() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          const video = this.$refs.video;
          if ('srcObject' in video) {
            video.srcObject = stream;
          } else {
            video.src = window.URL.createObjectURL(stream);
          }
          video.play();
          this.video_stream = stream;
          this.toggle_photo_sections();
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    },
    take_photo() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.photo_taken = true;
      this.photo_src = canvas.toDataURL();
      if (this.is_mobile_view) {
        $("#take_photo").hide();
        $("#submit_photo").show();
        $(".retake-photo").removeClass("d-none");
      }
    },
    showPopup() {
      $('#show_instruction').modal('show');
    },
    retake_photo() {
      this.photo_taken = false;
      this.photo_src = '';
      this.showPopup(); // Trigger the modal when retaking the photo

      if (this.is_mobile_view) {
        $("#take_photo").show();
        $("#submit_photo").hide();
      }
    },
    submit_photo() {
      const TOKEN = localStorage.getItem('token');
      const CLIENT_ID = this.client_id;
      const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/verify_company_api/save_facial_biometric_image/`;
      const DATA = {
        id_number: CLIENT_ID,
        photo_src: this.photo_src
      };
      $.ajax({
        url: SAFPS_URL,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          var liveness_value = response.liveness;
          var attempt_left = response.attempt;
          var status_message = response.status;
          
          if (liveness_value == "User's image is too dark") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else if (liveness_value == "User is wearing a hat") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else if (liveness_value == "User is wearing glasses") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else if (liveness_value == "User is not looking at the camera") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else if (liveness_value == "User is using a digital device") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else if (liveness_value == "There are two faces in the image") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else if (liveness_value == "Face is not visible") {
            Swal.fire({
              icon: 'error',
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          } else {
            if (status_message == "Successfully Verified") {
                localStorage.setItem('user_state', 'verified');
                this.$router.push('/company_verification');
            } else if (status_message == "Home Affairs is down") {
              Swal.fire({
                icon: 'error',
                text: "Our service is currently down. Please try again later",
              }).then(() => {
                this.$router.push('/login');
              })
            } if (status_message == "Failed Verification") {
              Swal.fire({
                icon: 'error',
                title: status_message,
                text: "You have " + attempt_left + " attempt left",
              });
            } else if (status_message == "You have no more attempts left") {
              Swal.fire({
                icon: 'error',
                title: status_message,
              }).then(() => {
                this.$router.push('/company_pending');
              });
            }
          }  
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error retrieving data';

          if (error.responseText.trim()) {
            const ERROR_DATA = JSON.parse(error.responseText);
            if (ERROR_DATA && ERROR_DATA.data) {
              error_message = ERROR_DATA.data;
              $('.error-message').text(error_message).removeClass('d-none');
              setTimeout(() => {
                $('.error-message').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    stop_camera() {
      if (this.video_stream) {
        const tracks = this.video_stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        this.video_stream = null;
      }
    },
    toggle_photo_sections() {
      this.is_mobile_view = window.innerWidth <= 768;
      if (this.is_mobile_view) {
        // Mobile view
        $("#take_photo").show();
        $("#submit_photo").hide();
      } else {
        // Desktop view
        $("#take_photo").show();
        $("#submit_photo").show();
      }
    },
    handle_window_resize() {
      this.toggle_photo_sections();
    }
  },
  mounted() {
    this.initialize_camera();
    this.get_id_number(); 
    window.addEventListener('resize', this.handle_window_resize);
  },
  beforeDestroy() {
    this.stop_camera();
    window.removeEventListener('resize', this.handle_window_resize);
  },
  beforeRouteLeave(to, from, next) {
    this.stop_camera();
    next();
  },
};