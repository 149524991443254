export default {
  name: 'ProjectDashboard',
  data: () => ({
    workflows: [],
    workflow_name: '',
    workflow_description: '',
    customers: 0,
    active_workflows: 0,
    admins:0,
    project_name: '',
    project_description: '',
    loading:false,
    role: ''
  }),
  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    }
  },
  methods: {
    get_project_data() {
      const TOKEN = localStorage.getItem('token');
      const GET_PROJECT_DATA_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_project_data_api/${this.project_id}`;
  
      $.ajax({
        url: GET_PROJECT_DATA_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.customers = response.customers;
          this.active_workflows = response.active_workflows;
          this.admins = response.admins || 0;
          this.project_name = response.project_name
          this.project_description = response.project_description
        },
        error: () => {
          Swal.close();
        }
      });
    },
    get_workflows() {
      const TOKEN = localStorage.getItem('token');
      const GET_WORKFLOWS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_project_workflows/${this.project_id}`;
  
      $.ajax({
        url: GET_WORKFLOWS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.workflows = Array.isArray(response.data) ? response.data : []; // Handle null or non-array responses
          this.role = response.user_role;
          this.loading = false;
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable('#workflow-table')) {
              $('#workflow-table').DataTable().destroy(); // Destroy existing instance if already initialized
            }
            $('#workflow-table').DataTable({
              responsive: true, // Add any additional DataTable options here
              paging: true,
              searching: true,
              ordering: true,
            });
          });
        },
        error: () => {
          this.loading = false;
          Swal.close();
        }
      });
    },
    format_date(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    get_status(status) {
      if (status === 'pending') {
        return 'status-pending';
      } else if (status === 'inactive') {
        return 'status-inactive';
      } else if (status === 'active') {
        return 'status-active';
      } else if (status === 'incomplete') {
        return 'status-incomplete';
      }
    },
    validate_inputs() {
      if (!this.workflow_name.trim()) {
        $('.workflow-name-messages').removeClass('d-none');
        setTimeout(() => {
          $('.workflow-name-messages').addClass('d-none');
        }, 3000);
        return false;
      }
      
      if (!this.workflow_description.trim()) {
        $('.workflow-description-messages').removeClass('d-none');
        setTimeout(() => {
          $('.workflow-description-messages').addClass('d-none');
        }, 3000);
        return false;
      }
      
      return true;
    },
    create_workflow() {
      if (!this.validate_inputs()) {
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const COMPANY_PROFILE_ID = localStorage.getItem('company_profile_id');

      const FORM_DATA = {
        workflow_name: this.workflow_name,
        workflow_description: this.workflow_description,
        company_profile_id: COMPANY_PROFILE_ID,
      };

      const CREATE_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/create_workflow_api/${this.project_id}`;

      $.ajax({
        url: CREATE_WORKFLOW_API,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          localStorage.setItem('user_state', 'created_workflow');
          let status = response.status;

          if (status === 'success') {
            const workflow_id = response.workflow_id;
            const project_id = response.project_id;
            this.$store.commit('set_workflow_id', workflow_id);
            this.$store.dispatch('update_project_id', project_id);
            $('#create_workflow').modal('hide');
            this.$router.push({ name: 'workflow_services' });
          }
          else {
            let error_message = response.message;
            // Display the error message using SweetAlert2
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonColor: '#59FF74',
              showConfirmButton: true
            });
          }
        },
        error: (error) => {
          Swal.close();

          let error_message = 'Error Creating Workflow';

          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;

            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;

              $('.error-messages').text(error_message).removeClass('d-none');

              setTimeout(() => {
                $('.error-messages').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    view_workflow(){
      this.$router.push({ name: 'view_workflow' });
    },
    edit_workflow(workflow_id){
      this.$store.dispatch('update_workflow_id', workflow_id);
      this.$router.push({ name: 'edit_workflow' });
    },
    deactivate_workflow(workflow_id){
      const TOKEN = localStorage.getItem('token');
      const DEACTIVATE_WORKFLOW_URL = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/action_workflow/${workflow_id}`;

      const FORM_DATA = {
        workflow_id: workflow_id,
        action: 'deactivate'
      };

      $.ajax({
        url: DEACTIVATE_WORKFLOW_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            confirmButtonText: 'OK'
          }).then(() => {
            this.get_workflows();
          });
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
    activate_workflow(workflow_id){
      const TOKEN = localStorage.getItem('token');
      const ACTIVATE_WORKFLOW_URL = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/action_workflow/${workflow_id}`;

      const FORM_DATA = {
        workflow_id: workflow_id,
        action: 'activate'
      };

      $.ajax({
        url: ACTIVATE_WORKFLOW_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            confirmButtonText: 'OK'
          }).then(() => {
            this.get_workflows();
          });
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
  },
  mounted() {
    this.get_project_data();
    this.get_workflows();
  }
};
