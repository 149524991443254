export default {
  name: 'UserLogin',
  data: () => ({
    username: '',
    password: '',
    dynamic_fields: [],
    reset_url: `${process.env.API_BASE_URL}/verify_api/password_reset/`
  }),
  mounted(){
    const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      if (GET_CLIENT_ID) {
        this.get_customer_login_form();
      }
  },
  methods: {
    get_customer_login_form(){
      const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_CUSTOMER_LOGIN_FORM_URL = `${BASE_URL}/verify_api/system_management_api/get_login_form_api/?client_admin_id=${GET_CLIENT_ID}`;
    
      $.ajax({
        method: "GET",
        url: GET_CUSTOMER_LOGIN_FORM_URL,
        contentType: 'application/json',
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();

          if (response.form_questions && Array.isArray(response.form_questions)) {
            this.dynamic_fields = response.form_questions.map(question => {
              // Handle different types of fields based on response flags
              return {
                category_id: question['category_id'],
                question_id: question['question_id'],
                question: question['question__question'],
                is_required: question['question__is_required'],
                question_number: question['question__number'],
                has_checkbox: question['question__has_checkbox'],
                has_date: question['question__has_date'],
                has_file: question['question__has_file'],
                has_number: question['question__has_number'],
                has_selection: question['question__has_selection'],
                has_text: question['question__has_text'],
                options: question['question__has_checkbox'] 
                         ? question['question__checkbox__option'] 
                         : question['question__has_selection'] 
                         ? question['question__selectionoption__option'] 
                         : [], // Options only relevant for checkbox or selection fields
                value: '',  // Placeholder for storing the value
                errorMessage: ''
              };
            });
          }
        },
        error: () => {
          Swal.close();
        }
      });
    },

    login() {

      if (!this.username.trim()) {
        $('.username-message').removeClass('d-none');
        setTimeout(() => {
          $('.username-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!this.password.trim()) {
        $('.password-message').removeClass('d-none');
        setTimeout(() => {
          $('.password-message').addClass('d-none');
        }, 3000);
        return false;
      }

      const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      const LOGIN_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/login_api/`;
      const FORM_DATA = {
        client_id : GET_CLIENT_ID,
        username: this.username,
        password: this.password,
        responses: this.dynamic_fields.map(field => ({
          category_id: field.category_id,
          question_id: field.question_id,
          value: field.value
        }))
      };

      $.ajax({
        url: LOGIN_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          localStorage.setItem('user_id', response.user_id);
          localStorage.setItem('token', response.token);
          localStorage.setItem('user_state', 'logged_in');
          this.handleLogin();
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error Logging in'
          if (error.responseText) {
            const ERROR_RESPONSE = JSON.parse(error.responseText);
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
              $('.login-message').text(error_message).removeClass('d-none');
              setTimeout(() => {
                $('.login-message').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    handleLogin() {
      this.$router.push({ name: 'user_otp' });
    }
  }
};