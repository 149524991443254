export default {
  name: 'ArrangeWorkflow',
  data: () => ({
    services: [],
    workflow: [],
    arranged_workflow: [],
    is_selected: false,
    order_saved: false,
    selectedService: null,
    selectedOrder: null,
    maxOrder: 0
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    sorted_services() {
      //Services based on service_order and return the services array
      return this.services;
    }
  },

  methods: {
    get_selected_services_for_workflow() {
      const TOKEN = localStorage.getItem('token');
      const GET_SELECTED_SERVICES_FOR_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_services/${this.workflow_id}`;
    
      $.ajax({
        url: GET_SELECTED_SERVICES_FOR_WORKFLOW_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        
        success: (response) => {
          Swal.close();
          if (response.status === 'success' && Array.isArray(response.data)) {

            this.services = response.data.map((item, index) => ({
              ...item.service,
              service_id: item.project_service_id,
              count: response.data.length,
              service_order: item.service_order || index + 1,
              selected_order: item.service_order || null
            }));

            this.maxOrder = this.services.length;
            this.get_saved_order_of_service(); // Fetch saved order if any
          } else {
            Swal.fire({
              text: 'Unexpected response format.',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'OK'
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = 'There was an error fetching selected services';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    get_saved_order_of_service() {
      const TOKEN = localStorage.getItem('token');
      const GET_SAVED_ORDER_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_service_order/${this.workflow_id}`;

      $.ajax({
        url: GET_SAVED_ORDER_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          if (response.status === 'success' && Array.isArray(response.data)) {
            response.data.forEach(savedService => {
              const matched_service = this.services.find(service => service.service_id === savedService.project_service_id);
              if (matched_service) {
                matched_service.service_order = savedService.service_order;
                matched_service.selected_order = savedService.service_order; // Update display order
              }
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = 'There was an error getting the saved order of services';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    open_modal(service) {
      this.selectedService = service;
      this.selectedOrder = service.selected_order || service.service_order || 1;
    },

    close_modal() {
      this.selectedService = null;
      this.selectedOrder = null;
      const modal = document.getElementById('yourModal');
      if (modal) {
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.style.display = 'none';
      }
    },

    save_order() {
      // Prevent duplicate orders
      const existing_service_with_same_order = this.services.find(
        service => service.service_order === this.selectedOrder && service.service_id !== this.selectedService.service_id
      );

      if (existing_service_with_same_order) {
        existing_service_with_same_order.service_order = null; // Remove order from existing service
        existing_service_with_same_order.selected_order = null;
      }

      if (this.selectedService) {
        this.selectedService.service_order = this.selectedOrder;
        this.selectedService.selected_order = this.selectedOrder;
        this.save_order_of_service(this.selectedService);
        this.order_saved = true;
        this.close_modal();
      }
    },

    save_order_of_service(service) {
      const TOKEN = localStorage.getItem('token');
      const ARRANGE_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/arrange_workflow_api/${this.workflow_id}`;

      const FORM_DATA = {
        service_id: service.service_id,
        service_order: service.service_order
      };

      $.ajax({
        url: ARRANGE_WORKFLOW_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(FORM_DATA),
        success: () => {
        },
        error: (error) => {
          Swal.close();
          let error_message = 'There was an error saving the order of service';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    validate_order_selection() {
      // Ensure all services have a unique order number
      const orders = this.services.map(service => service.service_order).filter(order => order !== null);
      const unique_orders = new Set(orders);

      if (orders.length !== unique_orders.size) {
        Swal.fire({
          icon: 'error',
          title: 'Duplicate Orders',
          text: 'Please ensure all services have a unique order number.',
          confirmButtonText: 'OK'
        });
        return false;
      }

      // Check if all services have been assigned an order
      if (orders.length !== this.services.length) {
        Swal.fire({
          icon: 'error',
          title: 'Incomplete Order',
          text: 'All services must have an assigned order before proceeding.',
          confirmButtonText: 'OK'
        });
        return false;
      }

      return true;
    },

    route_to() {
      if (this.validate_order_selection()) {
        this.$router.push({
          name: 'upload_consent'
        });
      }
    }
  },

  mounted() {
    this.get_selected_services_for_workflow();
  }
};