<div class="container-fluid">
  <nav class="navbar bg-white mt-2 rounded-3">
    <div class="d-flex align-items-center ms-3 flex-grow-1">
      <div class="d-flex align-items-center">
        <i 
        v-if="hide_burger_icon()"
        class="fa-solid fa-bars fa-xl me-3 fs-6"
        type="button"
        @click="toggle_sidebar">
        </i>
        
        <div v-if="role === 'admin'">
          <span class="fw-normal text-xs">Dashboard</span>/<span class="fw-bold text-xs">Admin</span>
        </div>
        <div v-if="role === 'client'">
          <i 
          v-if="hide_back_icon()"
          type="button"
          class="fa-solid fa-left-long fa-l green-color me-2"
          @click="go_back">
        </i>
        </div>
        <div v-if="user_info.user_role == 'SystemAdmin'">
          <span class="fw-normal text-xs">Dashboard</span>/<span class="fw-bold text-xs">Admin</span>
        </div>
        <div v-if="user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client'">
          <span 
            v-if="hide_back_icon()" 
            class="back-label text-xs text-dark rounded-pill me-1" 
            @click="go_back">
            Back to Projects
          </span>
          <!-- Display the slash only if the back icon is shown -->
          <span v-if="hide_back_icon()" class="text-xs text-dark">/</span>
          <!-- Display the page title if it's not empty -->
          <span v-if="page_title" class="page-title text-xs fw-bold ms-1">{{ page_title }}</span>
        </div>
        
      
       
      </div>
    </div>
    <div class="d-flex align-items-center mt-2 mb-2">
      <div class="search-bar me-4">
        <span class="input-group-text border-0 bg-transparent" id="search-addon">
          <i class="fas fa-search"></i>
        </span>
        <input class="search-bar border-0 text-xs" type="text" placeholder="Search...">
      </div>
      <span class="user-icon rounded-circle bg-green text-black d-flex justify-content-center align-items-center fw-bold custom-size">
        {{ get_initial(user_info.first_name) }}
      </span>
      <span class="user-name text-xs ms-2 me-3">{{ user_info.first_name }} {{ user_info.last_name }}</span>
    </div>
  </nav>
</div>