<div class="container-fluid">
  <div class="card rounded-3 mt-4 p-0">
      <div class="card-header bg-white border-0">
        Instant Verify Request
      </div>
    <div class="container mt-3">
      <div class="card-body">
        <table id="applications-table" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7">CLIENT</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">DATE CREATED</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(client, index) in requested_clients" :key="client.id">
              <td class="text-xxs text-start">{{ index + 1 }}</td>
              <td class="text-xxs ">
                <h6 class="mb-0 text-xxs">{{ client.first_name }} {{ client.last_name}}</h6>
                <p class="text-xxs fw-bold">{{ client.email }}</p>
              </td>
              <td class="text-xs text-start">{{ client.date_joined }}</td>
              <td>
                <button class="btn bg-green btn-sm text-xxs fw-bold rounded-small me-2 shadow" @click="view_client_logs(client.id)">View logs</button>
                <button class="btn bg-green btn-sm text-xxs fw-bold rounded-small me-2 shadow" @click="view_client_services(client.id)">View Services</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
    </div>
    </div>
</div>