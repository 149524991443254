import { validate_id, allow_num  } from "@/utils/validations";

export default {
  name: 'IncomeEstimator',

  data:() =>({ id_number: '', loading: false,  error: ''}),

  methods: {
    allow_num(event) {
        this.id_number = allow_num(event);
    },

    submit_id() {
      if (!this.id_number.trim()) {
        $('.id-message').removeClass('d-none');
        setTimeout(() => {
          $('.id-message').addClass('d-none');
        }, 3000);
        return false;
      }

      if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
          $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
      }
      this.income_estimator();
    },

    income_estimator() {
      const TOKEN = localStorage.getItem('token');
      const INCOME_ESTIMATOR_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/income_estimator/`;
    
      this.loading = true;
      this.error = '';

      $.ajax({
        url: INCOME_ESTIMATOR_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          id_number: this.id_number,
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
          this.loading = false;
          Swal.close();

          if (response == 'Successfully verified') {
            Swal.fire({
              icon: 'success',
              title: 'Successful',
              confirmButtonText: 'OK'
            });
          }  else if (response == 'No Status found in outer result.') {
            Swal.fire({
              icon: 'error',
              title: 'An error occurred while estimating customer income.',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$router.push('/');
            });
          } else if (response == 'No Record Found.') {
            Swal.fire({
              icon: 'error',
              title: 'No record found.',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$router.push('/');
            });
          } else if (response == 'No results found in response.') {
            Swal.fire({
              icon: 'error',
              title: 'An error occurred while estimating customer income.',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$router.push('/');
            });
          }
        },
        error: (error) => {
          this.loading = false;
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
            $('.error-message').text(error_message).removeClass('d-none');
                setTimeout(() => {
                    $('.error-message').text('').addClass('d-none');
                }, 3000);
          }else {
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
            }
        }
      });
    }
  }
};