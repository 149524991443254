import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';

export default {
  name: 'ThankYouPage',

  data: () => ({ workflowName: '' }),
  methods: {
    get_workflow_name() {
      const TOKEN = localStorage.getItem('token');
      const GET_WORKFLOW_NAME = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_api/`;

      $.ajax({
        url: GET_WORKFLOW_NAME,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.workflowName = response.workflow_name; 
        },
        error: () => {
          Swal.close();
        }
      });
    },
  },
  mounted() {
    this.get_workflow_name();
  }
};
