import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
    name: 'AMLVerification',
    mixins: [MAIN_CONTROLLER],
    data: () => ({ 
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        country_code: '',
        error: '',
        json_data: {}
    }),

    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      }
    },

      methods: {
    
        anti_money_laundering_application_api() {
          const TOKEN = localStorage.getItem('token');
          const ANTI_MONEY_LAUNDERING_APPLICATION_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/anti_money_laundering_application/`;
    
          this.error = '';
    
          $.ajax({
            url: ANTI_MONEY_LAUNDERING_APPLICATION_API,
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            data: JSON.stringify({
                first_name: this.first_name,
                last_name: this.last_name,
                date_of_birth: this.date_of_birth,
                workflow_id: this.workflow_id
            }),
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
              });
            },
            success: (response) => {
                let application_id = response.application_id;
                this.anti_money_laundering_api(application_id);
            },
            error: (error) => {
              Swal.close();
              let error_message = 'Something went wrong!';

              if (error.responseJSON && error.responseJSON) {
                error_message = error.responseJSON;
                $('.error-message').text(error_message).removeClass('d-none');
                    setTimeout(() => {
                        $('.error-message').text('').addClass('d-none');
                    }, 3000);
            }else {
                $('.error-message').text(error_message).removeClass('d-none');
                    setTimeout(() => {
                        $('.error-message').text('').addClass('d-none');
                    }, 3000);
              }
            }
          });
        },
        anti_money_laundering_api(application_id) {
            const TOKEN = localStorage.getItem('token');
            const ANTI_MONEY_LAUNDERING_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/anti_money_laundering/`;
      
            this.error = '';
      
            $.ajax({
              url: ANTI_MONEY_LAUNDERING_API,
              method: 'POST',
              contentType: 'application/json',
              headers: {
                Authorization: `Token ${TOKEN}`
              },
              data: JSON.stringify({
                    application_id: application_id,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    date_of_birth: this.date_of_birth,
                    gender: this.gender,
                    country_code: this.country_code,
                    workflow_id: this.workflow_id
              }),
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => Swal.showLoading(),
                });
              },
              success: () => {
                Swal.close();
                this.fetch_current_service();
              },
              error: (error) => {
                Swal.close();
                let error_message = 'Something went wrong!';
      
                if (error.responseJSON) {
                  if (error.responseJSON === "Invalid date of birth") {
                    error_message = error.responseJSON;
                    $('.invalid-dob').text(error_message).removeClass('d-none');
                      setTimeout(() => {
                          $('.invalid-dob').text('').addClass('d-none');
                      }, 3000);
                  }
                  error_message = error.responseJSON;
                  $('.error-message').text(error_message).removeClass('d-none');
                      setTimeout(() => {
                          $('.error-message').text('').addClass('d-none');
                      }, 3000);
              }else {
                  $('.error-message').text(error_message).removeClass('d-none');
                      setTimeout(() => {
                          $('.error-message').text('').addClass('d-none');
                      }, 3000);
                }
              }
            });
        }
      }
    };