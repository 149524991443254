import NavBar from '@/components/NavBar/NavBar.vue';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';

export default {
  name: 'QuestionManagement',
  pageTitle: 'Question Management',
  components: {
    NavBar
  },
  data: () => ({
    create_question_data: { question: '', question_number: '', question_type: '', mandatory: '', options: [], number_of_options: 0},

    edit_question_data: { question: '', question_number: '', question_type: '', mandatory: '', options: [] },

    questions: [],
    question_types: [],
    total_questions: 0,
    question_id: '',
    question_status: '',
    loading: false
  }),
  methods: {
    create_question() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const ADD_QUESTION_URL = `${BASE_URL}/verify_api/question_management_api/save_question_api/`;

      let options = this.create_question_data.options;
      if (this.create_question_data.question_type !== 'Selection' && this.create_question_data.question_type !== 'Checkbox') {
          options = [];  // Clear options if not relevant for the question type
      }
      const FORM_DATA = {
        question: this.create_question_data.question,
        question_number: this.create_question_data.question_number,
        question_type: this.create_question_data.question_type,
        mandatory: this.create_question_data.mandatory,
        options: options
      };

      $.ajax({
        method: "POST",
        url: ADD_QUESTION_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.fire({
            icon: 'success',
            text: 'Question successfully created.',
            showConfirmButton: false,  // Hide the confirm button
            timer: 3000
          }).then(() => {
            window.location.reload();
          });
        },
        error: (error) => {
          Swal.close();
          let error_message = 'An error occurred while creating the question.';
          
          if (error.responseJSON && error.responseJSON.error) {
            error_message = error.responseJSON.error;
          }

          this.showErrorMessage(error_message);
        }
      });
    },
    showErrorMessage(message) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        confirmButtonText: 'OK'
      });
    },
    get_questions(){
      const TOKEN = localStorage.getItem('token');
      const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/question_management_api/get_questions_api/`;

      $.ajax({
        url: GET_QUESTIONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.questions = response.questions || [];
          this.question_types = response.question_types || [];
          this.total_questions = response.total_questions;

          this.loading = false; // Set loading to false after data is loaded
          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          });
        },
        error: () => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = 'Error Getting questions';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#question_table')) {
          $('#question_table').DataTable().destroy();
        }
        $('#question_table').DataTable({
          responsive: true
        });
      });
    },
    edit_question(question_id) {
      const question = this.questions.find(ques => ques.id === question_id);
      if (question) {
        this.selected_question_id = question_id; // Set the selected project ID here
        this.edit_question_data.question = question.question;
        this.edit_question_data.question_number = question.number;
        this.edit_question_data.question_type = question.question_type;
        this.edit_question_data.mandatory = question.is_required;
        this.edit_question_data.options = question.options;

        this.$nextTick(() => {
          $('#edit_question_modal').modal('show');
        });
      }
    },
    update_question(){
      const TOKEN = localStorage.getItem('token');
      const EDIT_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/update_question_api/`;

      const FORM_DATA = {
        question_id: this.selected_question_id, // Set the selected project ID here
        question: this.edit_question_data.question,
        question_number: this.edit_question_data.question_number,
        question_type: this.edit_question_data.question_type,
        mandatory: this.edit_question_data.mandatory,
        options: this.edit_question_data.options
      };

      $.ajax({
        url: EDIT_QUESTION_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating question...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          $('#edit_question_modal').modal('hide');
          this.get_questions(); // Refresh the questions list
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
    validate_number_of_options() {
      if (this.create_question_data.number_of_options > 25) {
        this.create_question_data.number_of_options = 25;
        Swal.fire({
          title: "Too Many Options!",
          text: "You cannot enter more than 25 options.",
          icon: 'error',
          timer: 2000,
          showConfirmButton: true
        });
      }
      // Update the options array dynamically
      this.update_options_array();
    },
    update_options_array() {
      // If the number of options is reduced, remove excess options
      if (this.create_question_data.number_of_options < this.create_question_data.options.length) {
        this.create_question_data.options.splice(this.create_question_data.number_of_options);
      }
      // If the number of options is increased, add empty slots
      while (this.create_question_data.options.length < this.create_question_data.number_of_options) {
        this.create_question_data.options.push('');
      }
    },
    deactivate_question(question_id){
      const TOKEN = localStorage.getItem('token');
      const DEACTIVATE_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/change_question_status_api/`;

      const FORM_DATA = {
        question_id: question_id, // Set the selected project ID here
        question_status: 'Inactive'
      };

      $.ajax({
        url: DEACTIVATE_QUESTION_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating question status...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          this.get_questions(); // Refresh the questions list
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
    activate_question(question_id){
      const TOKEN = localStorage.getItem('token');
      const ACTIVATE_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/change_question_status_api/`;

      const FORM_DATA = {
        question_id: question_id, // Set the selected project ID here
        question_status: 'Active'
      };

      $.ajax({
        url: ACTIVATE_QUESTION_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating question status...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          this.get_questions(); // Refresh the questions list
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    }
  },
  computed: {
    check_question_type(){
      return this.create_question_data.question_type === 'Checkbox' || this.create_question_data.question_type === 'Selection';
    },
    check_edit_question_type() {
      return this.edit_question_data.question_type === 'Checkbox' || this.edit_question_data.question_type === 'Selection';
    }
  },
  mounted() {
    this.get_questions();
  }
};