<div class="container-fluid">
    <div class="row mt-0">
        <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4">
            <div class="card-header bg-white border-0 text-dark">Logs</div>
            <div class="card-body">
            <table id="logs_table" class="table-responsive w-100">
                <thead class="bg-dark">
                    <tr>
                        <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start text-white">No.</th>
                        <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start text-white">Verification Status</th>
                        <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7 text-start text-white">Date</th>
                    </tr>
                </thead>
                <tbody>
                <tr class="text-xs" v-for="(log, index) in logs" :key="log.id">
                    <td class="text-xxs text-start">{{ index + 1 }}</td>
                    <td class="text-xxs text-start">{{ log.api_result_status }}</td>
                    <td class="text-xxs text-start">{{ log.date_created }}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        </div>
    </div>
</div>