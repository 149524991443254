<div class="container-fluid">
  <div class="row justify-content-center align-items-center">
    <div class="card card-description border-3 col-6">
      <section class="section text-xs">
        <div v-if="service_description">
          <h5 class="mt-3 d-flex fw-bold">{{ service_description.service_name }}</h5>
          <p><span id="service d-flex">{{ service_description.service_description }}</span></p>
          <div class="decorative-card">
              <p><strong>This service returns information such as:</strong></p>
              <ul id="service_output">
                <li v-for="output in service_description.service_output" :key="output">{{ output }}</li>
              </ul>
          </div>
          <div class="mt-4 decorative-card">
              <p><strong>Required Inputs:</strong></p>
              <ul id="service_input">
                <li v-for="input in service_description.service_input" :key="input">{{ input }}</li>
              </ul>
              <p><strong>Cost Per Request:</strong></p>
              <ul>
                  R <span id="service_cost">{{ service_description.service_cost }}</span>
              </ul>
          </div>
        </div>
      </section> 
    </div>
  </div>
</div>