export default {
  name: 'edit_workflow',

  data: () => ({ workflow_name: '', workflow_description: '', workflow_status: '', workflow_data: null }),

  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    steps() {
      if (!this.workflow_data) {
        return [];
      }

      return [
        { 
          step_name: 'Create workflow', 
          status: this.workflow_data.workflow_exists ? 'completed' : 'pending', 
          path: `/client/arrange_workflow/` 
        },
        { 
          step_name: 'Choose Services', 
          status: this.workflow_data.workflow_services_exist ? 'completed' : 'pending', 
          path: `/client/workflow_services/` 
        },
        { 
          step_name: 'Order Services', 
          status: this.workflow_data.workflow_services_order_exists ? 'completed' : 'pending', 
          path: `/client/arrange_workflow/` 
        },
        {
          step_name: 'Upload Consent',
          status: this.workflow_data.consent_uploaded ? 'completed' : 
                  this.workflow_data.selected_use_terms_and_conditions ? 'completed' : 
                  'pending',
          path: `/client/upload_consent/`
        },
        { 
          step_name: 'Reasons for Usage', 
          status: this.workflow_data.reason_exists ? 'completed' : 'pending', 
          path: `/client/reasons_for_usage/` 
        },
        { 
          step_name: 'Confirm Workflow', 
          status: this.workflow_data.confirmed_workflow_exists ? 'completed' : 'pending', 
          path: `/client/confirm_workflow/` 
        }
      ];
    },

    workflow_status_class() {
      switch (this.workflow_status.toLowerCase()) {
        case 'pending':
          return 'text-success'; // Green for pending status
        case 'inactive':
          return 'text-warning'; // Yellow for inactive status
        case 'incomplete':
          return 'text-danger'; // Red for incomplete status
        default:
          return 'text-muted'; // Gray for unknown status
      }
    }
  },

  methods: {
    capitalize_first_letter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    get_workflow_information() {
      const TOKEN = localStorage.getItem('token');
      const GET_WORKFLOW_INFORMATION = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_data_api/${this.workflow_id}`;

      $.ajax({
        url: GET_WORKFLOW_INFORMATION,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          if (response.workflow_data) {
            const workflow = response.workflow_data[0]; // Ensure this is the correct structure

            this.workflow_name = this.capitalize_first_letter(workflow.workflow_name || '');
            this.workflow_description = this.capitalize_first_letter(workflow.workflow_description || '');
            this.workflow_status = workflow.workflow_status || '';

            // Fetch progress after getting workflow information
            this.edit_workflow_progress();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Workflow data is missing.',
              confirmButtonText: 'OK'
            });
          }
        },
        error: (error) => {
          const error_message = error?.responseJSON?.error || 'Error fetching workflow details';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    edit_workflow_progress() {
      const TOKEN = localStorage.getItem('token');
      const GET_WORKFLOW_PROGRESS = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/edit_workflow_progress_api/${this.workflow_id}`;
    
      $.ajax({
        url: GET_WORKFLOW_PROGRESS,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.workflow_data = response; // Update workflow_data for the steps
        },
        error: () => {
          let error_message = 'Error fetching workflow progress';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    }
  },

  mounted() {
    this.get_workflow_information();
  }
};


