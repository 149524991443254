export default {
  name: 'CustomerConsent',

  data: () => ({
      get_consent: true,
      consent_provided: true,
  }),
  workflow_id() {
    return this.$store.getters.get_workflow_id;
  },

  methods: {
      allow_num(event) {
          let input_value = event.target.value;
          input_value = input_value.replace(/\D/g, '');
          this.id_number = input_value;
      },
      show_popup() {
          $('.customer_modal').show();
      },
      hide_popup(){
          $('.customer_modal').hide();  
      },
      get_terms_and_conditions() {
          const TOKEN = localStorage.getItem('token');
          const GET_CUSTOMER_TERMS_AND_CONDITIONS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_terms_and_conditions/?workflow_id=${this.workflow_id}`;
          //change url
          $.ajax({
            url: GET_CUSTOMER_TERMS_AND_CONDITIONS_API,
            method: 'GET',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });
            },
            success: (response) => {
              Swal.close();
              // Initialize reasons with server data
              this.get_consent = response.get_consent; //value to get
            },
            error: () => {
              Swal.close();
              
            }
          });
      },
      accept() {
        const TOKEN = localStorage.getItem('token');
        const CONCENT_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/terms_and_conditions_api/`;
    
        const FORM_DATA = {
            consent_provided: this.consent_provided
        };
    
        $.ajax({
            url: CONCENT_URL,
            method: 'POST',
            contentType: 'application/json',
            headers: {
                Authorization: `Token ${TOKEN}`
            },
            data: JSON.stringify(FORM_DATA),
            beforeSend: () => {
                Swal.fire({
                    text: 'Loading ...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            },
            success: () => {
                Swal.close();
                localStorage.setItem('user_state', 'consent_given');
                this.get_consent = false;  // Hide the modal and show the form
                // Navigate without reloading the page
                this.$router.push('/id_verification');
            },
            error: () => {
                Swal.close();
            }
        });
      },
      decline() {
          Swal.fire({
              title: 'Are you sure you want to decline?',
              text: "You cannot proceed with the application if consent is not provided.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes'
          }).then((result) => {
              if (result.isConfirmed) {
                  this.$router.push('/');
              }
          });
      },
  },

  mounted() {
      this.get_terms_and_conditions();
  },
  watch: {
      get_consent(consent) {
        if (consent) {
          this.hide_popup();
        } else {
          this.show_popup();
        }
      }
  },
};