import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import Sidebar from '@/components/sidebar/SidebarComponent.vue';

export default {
  name: 'ClientDashboard',
  components: {
    ClientSidebar,
    NavBar,
    Sidebar
  },
  data: () => ({is_sidebar_toggled: false }),

  computed: {
    hide_sidebar() {
      const routes_to_hide_sidebar = [
        'view_service',
        'client_services',
        'workflow_services',
        'create_workflows',
        'reasons_for_usage',
        'arrange_workflow',
        'upload_consent',
        'confirm_workflow',
        'view_workflow',
        'edit_workflow'
      ];
      // Default to hide sidebar based on route unless manually toggled
      const should_hide_by_route = routes_to_hide_sidebar.includes(this.$route.name);
      return should_hide_by_route ? !this.is_sidebar_toggled : this.is_sidebar_toggled;
    }
  },
  methods: {
    toggle_sidebar() {
      this.is_sidebar_toggled = !this.is_sidebar_toggled;
    }
  },
  watch: {
    '$route.name'() {
      // Reset manual toggle when route changes
      this.is_sidebar_toggled = false;
    }
  }
};

