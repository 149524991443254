import {show_error} from '@/utils/validations.js';
export default {
    name: 'CustomerIdVerification',
  
    data: () => ({
        id_number: '',
        dynamic_fields : []
    }),
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
  
    methods: {
        allow_num(event) {
            let input_value = event.target.value;
            input_value = input_value.replace(/\D/g, '');
            this.id_number = input_value;
        },
        get_customer_id_verification_form() {
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;
            const GET_CUSTOMER_ID_VERIFICATION_FORM_URL = `${BASE_URL}/verify_api/system_management_api/get_id_verification_api/?client_admin_id=${GET_CLIENT_ID}`;
          
            $.ajax({
              method: "GET",
              url: GET_CUSTOMER_ID_VERIFICATION_FORM_URL,
              contentType: 'application/json',
              headers: {
                Authorization: `Token ${TOKEN}`
              },
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
              },
              success: (response) => {
                Swal.close();
                if (response.form_questions && Array.isArray(response.form_questions)) {
                  this.dynamic_fields = response.form_questions.map(question => {
                    // Handle different types of fields based on response flags
                    return {
                      category_id: question['category_id'],
                      question_id: question['question_id'],
                      question: question['question__question'],
                      is_required: question['question__is_required'],
                      question_number: question['question__number'],
                      has_checkbox: question['question__has_checkbox'],
                      has_date: question['question__has_date'],
                      has_file: question['question__has_file'],
                      has_number: question['question__has_number'],
                      has_selection: question['question__has_selection'],
                      has_text: question['question__has_text'],
                      options: question['question__has_checkbox'] 
                               ? question['question__checkbox__option'] 
                               : question['question__has_selection'] 
                               ? question['question__selectionoption__option'] 
                               : [], // Options only relevant for checkbox or selection fields
                      value: '',  // Placeholder for storing the value
                      errorMessage: ''
                    };
                  });
                }
              },
              error: (error) => {
                Swal.close();
                let error_message = 'Getting registration form failed';
                if (error.responseJSON && error.responseJSON.error) {
                  error_message = error.responseJSON.error;
                } else {
                  error_message = 'An error occurred during retrieval of register form.';
                }
                show_error('.error-message', error_message);
              }
            });
        },
  
        // Function to validate the ID number
        validate_id(id_input) {
            var month = parseInt(id_input.substring(2, 4), 10) - 1;
            var day = parseInt(id_input.substring(4, 6), 10);
            var id_year = parseInt(id_input.substring(0, 2), 10);
    
            if (month < 0 || month > 11) {
            return false;
            }
    
            if (day < 1 || day > 31) {
            return false;
            }
    
            if (id_year < new Date().getFullYear() - 100) {
            id_year += 2000;
            } else {
            id_year += 1900;
            }
    
            var temp_date = new Date(id_year, month, day);
            var id_date = temp_date.getDate();
            var id_month = temp_date.getMonth();
    
            if (id_month !== month || id_date !== day) {
            return false;
            }
    
            var check_sum = 0;
            var multiplier = 1;
    
            for (var i = 0; i < 13; i++) {
            var digit = parseInt(id_input.charAt(i), 10);
            var temp_total = digit * multiplier;
    
            if (temp_total > 9) {
                temp_total = parseInt(temp_total.toString().charAt(0), 10) + parseInt(temp_total.toString().charAt(1), 10);
            }
    
            check_sum += temp_total;
            multiplier = (multiplier % 2 === 0) ? 1 : 2;
            }
    
            if ((check_sum % 10) !== 0) {
            return false;
            }
    
            return true;
        },
  
        submit_id() {
            
            if (!this.id_number.trim()) {
                $('.id-message').removeClass('d-none');
                setTimeout(() => {
                    $('.id-message').addClass('d-none');
                }, 3000);
                return false;
            }
    
            // Validate the ID number using the validate_id method
            if (!this.validate_id(this.id_number)) {
                $('.invalid-message').removeClass('d-none');
                setTimeout(() => {
                    $('.invalid-message').addClass('d-none');
                }, 3000);
                return false;
            }
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const SAVE_ID_VERIFICATION_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_id_verification_api/`;
            const FORM_DATA = {
              client_admin_id : GET_CLIENT_ID,
              id_number: this.id_number,
              responses: this.dynamic_fields.map(field => ({
                category_id: field.category_id,
                question_id: field.question_id,
                value: field.value
              }))
            };
    
            $.ajax({
                url: SAVE_ID_VERIFICATION_URL,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}` 
                },
                data: JSON.stringify(FORM_DATA),
                beforeSend: () => {
                    Swal.fire({
                    text: 'Loading ...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                    });
                },
                success: (response) => {
                    Swal.close();
                    localStorage.setItem('id_number', response.id_number);
                    localStorage.setItem('user_state', 'id_verified');
                    this.$router.push({ name: 'assigned_workflows'});
                    
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';
        
                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                        $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                    }else {
                        $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                    }
                }
            });
        }
    },
  
    mounted() {
        this.get_customer_id_verification_form();
    },
  };