<div class="container d-flex justify-content-center align-items-center min-vh-100">
  <div class="card shadow border-2 custom-border rounded-3 p-4 w-50">
    <div class="text-center">
      <p class="text-dark text-s fw-bolder mb-4 fs-5">CIPC Verification</p>
    </div>

    <div class="mb-3">
      <label for="company_name" class="form-label fw-bold text-xs">Company Name <span class="text-danger fw-bold">*</span></label>
      <div class="col">
        <input 
          id="company_name"
          type="text"
          class="form-control text-xs rounded-small p-3 shadow-sm"
          v-model="company_name" 
          placeholder="Enter Company Name"
        />
      </div>
      <small class="form-text text-danger d-none company-message text-xs fw-bold">
        Please fill out this field
      </small>
    </div>

    <div class="mb-3">
      <label for="reg_number" class="form-label fw-bold text-xs">Company Registration Number <span class="text-danger fw-bold">*</span></label>
      <div class="col">
        <input 
          id="reg_number"
          type="text"
          class="form-control text-xs rounded-small p-3 shadow-sm"
          v-model="reg_number"
          placeholder="CRN"
          @input="validate_reg_number"
        />
      </div>
      <small class="form-text text-danger d-none reg-message text-xs fw-bold">
        Please fill out this field
      </small>
      <small class="form-text text-danger d-none text-xs fw-bold error-message"></small>
    </div>

    <div class="text-end">
      <button 
        type="submit" 
        class="btn bg-green btn-sm mt-1 px-4 rounded-small shadow-sm fw-bold text-dark text-xs"
        @click="save_cipc_info"
      >
        Submit
      </button>   
    </div>
  </div>
</div>
