<div class="container-fluid">
  <div class="card view-card border-0 mt-5 rounded-3 shadow p-2">
    <!-- Verification Details -->
    <div class="row p-3">
        <!-- User Information -->
        <div class="mb-1">
            <h4 class="text-xs fw-bold">{{ name || 'N/A'  }}</h4>
            <p class="text-xs text-muted">Contact: 0{{ phone_number || 'N/A'  }}</p>
        </div>
        <!-- Facial Biometrics -->
        <div class="col-md-6 mb-3">
            <h5 class="text-xs fw-bold">Verification Details</h5>
            <div class="card view-card border bg-light mt-0 h-100 text-xs p-2">
                <h6>Facial Biometrics
                <span class="badge text-dark float-end position-relative">
                    <span class="position-absolute top-50 start-0 translate-middle text-xs">
                        <div class="form-check">
                        <input
                         v-model="checks.id_match"
                         class="form-check-input text-s"
                         type="checkbox"
                         checked
                         disabled
                        />
                        <label class="form-check-label visually-hidden" for="flexCheckDefault">
                            Passed
                        </label>
                        </div>
                    </span>
                    <span class="ms-1 ">passed</span>
                    </span>
                </h6>
                <p class="m-0"><strong>ID:</strong> {{ profile_information.id_number || 'N/A' }}</p>
                <p class="m-0"><strong>Verification Date:</strong> {{ biometrics_verification.date_created || 'N/A' }}</p>
            </div>
        </div>

        <!-- ID Match -->
        <div class="col-md-6 mb-3">
            <div class="card view-card border bg-light mt-4 h-100 text-xs p-2">
                <h6>ID Match
                    <span class="badge text-dark float-end position-relative">
                        <span class="position-absolute top-50 start-0 translate-middle">
                            <div class="form-check">
                            <input
                             v-model="checks.id_match"
                             class="form-check-input text-s"
                             type="checkbox"
                             checked
                             disabled
                            />
                            <label class="form-check-label visually-hidden" for="flexCheckDefault">
                                Passed
                            </label>
                            </div>
                        </span>
                        <span class="ms-1 ">passed</span>
                    </span>
                </h6>
                <p class="m-0"><strong>Relationship:</strong> {{ profile_information.relationship || 'N/A' }}</p>
            </div>
        </div>
        </div>

        <!-- Company Verification -->
        <div class="px-3 pb-3">
            <div class="card view-card border bg-light p-2 h-100 text-xs">
                <h6>Company Verification
                    <span class="badge text-dark float-end position-relative">
                        <span class="position-absolute top-50 start-0 translate-middle">
                          <div class="form-check">
                            <input
                             v-model="checks.company_verification"
                             class="form-check-input text-s"
                             type="checkbox"
                             checked
                             disabled
                            />
                            <label class="form-check-label visually-hidden" for="flexCheckDefault">
                              Passed
                            </label>
                          </div>
                        </span>
                        <span class="ms-1 ">passed</span>
                    </span>
                </h6>
                <p class="m-0"><strong>Company:</strong> {{ company_information.company_name || 'N/A' }}</p>
                <p class="m-0"><strong>Verification Date:</strong> {{ company_verification ? company_verification.date_created : 'N/A'  }}</p>
            </div>
        </div>
        <div class="text-end mt-4">
            <button  class="btn bg-green btn-sm text-xxs fw-bold rounded-small shadow me-2"
            @click="approve_application">Approve</button>
        </div>
</div>
</div>