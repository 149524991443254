export default {
    name: 'ClientRequestedServices',

    data: () => ({
        currentTab: localStorage.getItem('client_requested_servicesTab') || 'Approved',
        approved_requests: [],
        pending_requests: [],
        declined_requests: []
        
    }),

    computed: {
      client_id() {
        return this.$store.getters.get_client_id;
      }
    },

    methods: {
      switchTab(tab) {
      // Destroy the existing DataTable before switching tabs
      if ($.fn.dataTable.isDataTable('#serivcesTable')) {
          $('#serivcesTable').DataTable().destroy();
      }

      // Update the current tab and reinitialize the DataTable after the content is rendered
      this.currentTab = tab;
      localStorage.setItem('client_requested_servicesTab', tab);
      this.$nextTick(() => {
          this.initDataTable();
      });
      },
      initDataTable() {
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable('#serivcesTable')) {
              $('#serivcesTable').DataTable().destroy();
            }
            $('#serivcesTable').DataTable({
              responsive: true,
            });
          });
      },
      
      view_services_requested(){
        const TOKEN = localStorage.getItem('token');
        const GET_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/view_requested_services/${this.client_id}`;

        $.ajax({
          url: GET_SELECTED_SERVICES_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            this.approved_requests = response.data.Approved;
            this.declined_requests = response.data.Declined;
            this.pending_requests = response.data.Pending;
          },
          error: (error) => {
            Swal.close();
            let error_message = 'Failed to get selected services';
            if (error.responseJSON) {
              const ERROR_RESPONSE = error.responseJSON;
              if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                error_message = ERROR_RESPONSE.error;
              }
            }
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
      },

      action_service_requested(service_id,application_id,action){
        const TOKEN = localStorage.getItem('token');
        const ACTION_APPLICATION_URL = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/action_service_requested/`;
  
        $.ajax({
          url: ACTION_APPLICATION_URL,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify({
            service_id: service_id,
            action: action,
            application_id:application_id
          }),
          success: (response) => {
            Swal.fire({
              icon: 'success',
              text: response.message,
              confirmButtonText: 'OK'
            }).then(() => {
              window.location.reload()
            });
          },
          error: (response) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.responseJSON.error || 'An error occurred while processing the application.',
              confirmButtonText: 'OK'
            });
          }
        });
      },


    },

    mounted() {
      this.view_services_requested();
    }    
};