<div class="container-fluid">
  <div class="row justify-content-center mt-0">
    <div class="col-md-10">
      <div class="card rounded-3 p-0 mt-4 mx-auto">
        <div class="card-header bg-dark text-center p-2">
          <h6 class="card-heading fw-bold text-white text-s">Why do you need access to this data?</h6>
          <p class="card-heading fw-bold text-white text-s">Please select from the list below.</p>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped-custom">
              <thead class="bg-white">
                <tr>
                  <th class="fs-6 w-15">Reasons</th>
                  <th class="fs-6">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(reason, index) in reasons" :key="reason.id">
                  <td>
                    <p>{{ index + 1 }}. {{ reason.reason }}</p>
                  </td>
                  <td class="text-end">
                    <label class="switch">
                      <input type="checkbox" v-model="reason.is_selected" @change="toggle_selection(reason)">
                      <span class="slider round"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-end me-4 mb-5">
          <button type="button" class="btn bg-green rounded-pill text-s fw-bold" @click="next">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>