<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 mb-2">
      <div class="card card-bg shadow rounded-3 mt-4 customers-custom-card-width h-78">
        <div class="card-body d-flex flex-column align-items-center justify-content-center text-center p-0">
          <div class="icon mb-2">
            <img src="@/assets/images/customer-success.png" alt="Logo" class="icon" />
          </div>
          <h6 class="card-title fw-bold mb-2 mt-2">Customers</h6>
          <p class="text-success fw-bold number fs-4 mb-0">0</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-3">
          <div class="card-header bg-dark text-center p-2">
            <h6 class="card-heading fw-bold text-white text-s">Customers in your workflow</h6>
          </div>
          <div class="card-body">
            <table id="customers_table" class="table table-clear">
              <thead class="bg-gray">
                <tr>
                  <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">NO.</th>
                  <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Customer</th>
                  <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Date Created</th>
                  <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(customer, index) in customers" :key="customer.id">
                  <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                  <td class="text-xxs text-start">{{ customer.first_name }} {{ customer.last_name }}
                    <p class="text-xxs fw-bold">{{ customer.email }}</p>
                  </td>
                  <td class="text-xs fw-bold text-start">{{ format_date(customer.date_joined) }}</td>
                  <td>
                    <button class="btn bg-green rounded-3 fw-bold btn-sm me-2 text-xs">
                      View
                    </button>
                    <button class="btn btn-dark rounded-3 fw-bold btn-sm me-2 text-xs">
                      Audit trail
                    </button>
                    <button class="btn bg-green rounded-3 fw-bold btn-sm me-2 text-xs">
                      Documents
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</div>