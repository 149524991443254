import Swal from 'sweetalert2';
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

function luhnCheck(id) {
  let sum = 0;
  let shouldDouble = false;
  for (let i = id.length - 1; i >= 0; i--) {
    let digit = parseInt(id.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
}

export default {
  name: 'CreditReport',
  mixins: [MAIN_CONTROLLER],
  props: ['workflow_id'],

  data: () => ({ id_number: '', loading: false, error: '' }),

  methods: {
    allow_num(event) {
        this.id_number = event.target.value.replace(/\D/g, '');
    },

    submit() {
      if (!this.id_number.trim()) {
        this.showMessage('id-message', 'ID number is required');
        return false;
      }

      if (!luhnCheck(this.id_number)) {
        this.showMessage('invalid-message', 'ID number is invalid, please provide a valid ID number');
        return false;
      }

      this.credit_report();
    },

    showMessage(className, message) {
      let element = document.querySelector(`.${className}`);
      if (element) {
        element.innerText = message;
        element.classList.remove('d-none');
        setTimeout(() => {
          element.classList.add('d-none');
        }, 3000);
      }
    },

    credit_report() {
      const TOKEN = localStorage.getItem('token');
      const CREDIT_REPORT_BASE64_API = `${process.env.API_BASE_URL}/credit_verification/credit_report_b64/?workflow_id=${this.workflow_id}`;
    
      this.loading = true;
      this.error = '';

      $.ajax({
        url: CREDIT_REPORT_BASE64_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          id_number: this.id_number,
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
          this.loading = false;
          Swal.close();

          if (response.status === 'success') {
            Swal.fire({
              icon: 'success',
              title: 'Successfully Verified',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$nextTick(() => {
                this.routeToNextService();
              });
            });
          } else if (response.api_error_message) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.api_error_message,
            });
          } else {
            this.loading = false;
            this.error = 'An error occurred while retrieving customer credit report.';
          }
        },
        error: (xhr) => {
          this.loading = false;
          let response = xhr.responseJSON;
          if (response && response.api_error_message) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.api_error_message,
            });
          } else {
            this.loading = false;
            this.error = 'An error occurred while retrieving customer credit report.';
          }
        }
      });
    }
  }
};