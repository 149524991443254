import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import Sidebar from '@/components/sidebar/SidebarComponent.vue';

export default {
    name: 'ComponentDashboard',
    components: {
      NavBar,
      AdminSidebar,
      Sidebar
  },
};