import NavBar from '@/components/NavBar/NavBar.vue';

export default {
    name: 'FormManagement',
    pageTitle: 'Form Management', 
    components: {
        NavBar
    },
    data: () => ({
        categories: [],
        category: "",
        category_name: ''
    }),
    methods: {
        get_categories() {
          const TOKEN = localStorage.getItem('token');
          const GET_CATEGORIES_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_categories_api/`; //change link
          $.ajax({
            url: GET_CATEGORIES_API,
            method: 'GET',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              this.loading = true;
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });
            },
            success: (response) => {
            Swal.close();
            this.categories = response.data || [];
            this.loading = false;
            this.$nextTick(() => {
              this.initDataTable(); // Initialize DataTable after data is loaded
            });
            },
            error: () => {
              this.loading = false;
              Swal.close();
            }
          });
        },

        create_category() {
          const TOKEN = localStorage.getItem('token');

          const FORM_DATA = {
              category_name: this.category_name,
          };
    
          const CREATE_CATEGORY_URL = `${process.env.API_BASE_URL}/verify_api/form_management_api/create_category_api/`;
    
          $.ajax({
            url: CREATE_CATEGORY_URL,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(FORM_DATA),
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });
            },
            success: (response) => {
              Swal.close();
              const category_id = response.data.category_id;
              this.$store.commit('set_category_id', category_id);
              Swal.fire({
                  icon: 'success',
                  text: response.message,
                  confirmButtonColor: '#59FF74',
                  showConfirmButton: true
                  })
                  .then(() => {
                      window.location.reload();
                      $('#add_category').modal('hide');
                    });
              },
              error: (error) => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.responseJSON.message,
                    confirmButtonColor: '#59FF74',
                    showConfirmButton: true
                  });
              },
          });
        },

        manage_category(category_id){
          this.$store.dispatch('update_category_id', category_id);
          this.$router.push({ name: 'manage_form'});
        },
        initDataTable() {
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable('#category_table')) {
              $('#category_table').DataTable().destroy();
            }
            $('#category_table').DataTable({
              responsive: true
            });
          });
        },
  
      },
      mounted() {
        this.get_categories();
      }
  }
