<div class="customer-dashboard">
    <div class="wrapper">
        <!-- SidebarMenu component -->
        <CustomerSidebar/>
    
        <div class="container-fluid">
            <!-- NavbarMenu component -->
            <NavBar />

            <router-view ></router-view>
        </div>
    </div>
</div>