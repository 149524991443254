<form method="post" @submit.prevent="submit_id">
    <div class="text-center mt-3">
        <p class="text-dark fw-bold mb-3 text-x">Please Provide your ID Number</p>
    </div>
    
    <div class="form-group">
        <input type="text" class="form-control rounded text-xs" maxlength="13" minlength="13" v-model="id_number" placeholder="ID Number" @input="allow_num">
        <small class="text-danger p-2 text-xxs fw-bold d-none id-message">Please fill out this field</small>
        <small class="text-danger p-2 text-xxs fw-bold d-none invalid-message">ID Number invalid. Please provide a valid ID number</small>
        <small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>
    </div>

    <div class="text-center mt-5">
        <button type="submit" class="btn bg-green btn-lg shadow-lg rounded text-xs fs-6 mt-2 w-75 fw-bold">Continue</button>
    </div>
</form>