<div class="container-fluid">
    <div v-if="loading" class="text-center mt-4">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="card rounded-3 mt-5 p-0 border-0 shadow">
        <div class="card-header bg-white border-0">
          <span class="text-dark text-sm fw-500">Services</span>
          <router-link :to="{ name: 'instant_services' }" class="nav-link text-dark float-end">
            <button type="button" class="btn bg-dark btn-sm text-xs rounded-small text-white shadow">
                <i class="fas fa-plus"></i> Add Service
            </button>
        </router-link>
        </div>
        <div class="card-body">
          <ul class="nav nav-tabs mb-4 bg-white d-flex justify-content-between" id="myTab" role="tablist">
            <li class="nav-item flex-fill me-2" role="presentation">
              <button class="nav-link w-100 fw-bold text-dark text-xs border-0 " :class="{ active: currentTab === 'Approved' }"
                      @click="switchTab('Approved')">
                      Approved
              </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
              <button class="nav-link w-100 fw-bold text-dark text-xs border-0" :class="{ active: currentTab === 'Pending' }"
                      @click="switchTab('Pending')">
                      Pending
              </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
              <button class="nav-link w-100 fw-bold text-dark text-xs border-0" :class="{ active: currentTab === 'Declined' }"
                      @click="switchTab('Declined')">
                      Declined
              </button>
            </li>
          </ul>

          <div class="tab-content">
            <div v-if="currentTab === 'Approved'" class="tab-pane fade show active">
              <div class="table-responsive">
                <table  id="serivcesTable" class="table table-clear">
                  <thead class="bg-dark text-white">
                      <tr>
                          <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
                          <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Service Name</th>
                          <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="text-xs" v-for="(approved, index) in approved_requests" :key="approved.id">
                          <td class="text-xxs text-start">{{ index + 1 }}</td>
                          <td class="text-xxs text-start">{{ approved.service_name }}</td>
                          <td>
                            <button class="btn bg-green btn-sm text-xxs shadow rounded-small me-2 text-dark fw-bold" @click="view_service(approved.name)"><i class="fa fa-cog" aria-hidden="true"></i> Utilize</button>
                            <button class="btn bg-dark btn-sm text-xxs shadow rounded-small me-2 text-white" @click="view_logs(approved.service_id)"><i class="fa fa-tasks" aria-hidden="true"></i> Logs</button>
                          </td>
                      </tr>
                  </tbody>
                </table>
              </div>
             
            </div>
            <div v-if="currentTab === 'Pending'" class="tab-pane fade show active">
              <div class="table-responsive">
                <table id="serivcesTable" class="table table-clear">
                  <thead class="bg-dark text-white">
                      <tr>
                          <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
                          <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Service Name</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="text-xs" v-for="(pending, index) in pending_requests" :key="pending.id">
                        <td class="text-xxs text-start">{{ index + 1 }}</td>
                        <td class="text-xxs text-start">{{ pending.service_name }}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="currentTab === 'Declined'" class="tab-pane fade show active">
              <table id="serivcesTable" class="table table-clear">
                <thead class="bg-dark text-white">
                  <tr>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">ID</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Service Name</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-xs" v-for="(declined, index) in declined_requests" :key="declined.id">
                    <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                    <td class="text-xs fw-bold text-start">{{ declined.service_name }}</td>
                    <td>
                      <button class="btn bg-green btn-sm text-xxs fw-bold rounded-pill me-2">Request</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>      
</div>


<div class="modal" tabindex="-1" id="service_modal">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <component :is="service_map[selected_service]"></component>
      </div>
    </div>
  </div>
</div>