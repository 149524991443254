export default {
  name: 'ConfirmWorkflow',

  data: () => ({
    custom_services:[],
    workflow_services: [],
    workflow_data: '',
    total_costs: null,
    terms_and_conditions_document: ''
  }),
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    project_id() {
      return this.$store.getters.get_project_id;
    }
  },

  methods: {
    get_workflow_information(){
      const TOKEN = localStorage.getItem('token');
      const GET_WORKFLOW_INFORMATION = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_data_api/${this.workflow_id}`;

      $.ajax({
        url: GET_WORKFLOW_INFORMATION,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          
          Swal.close();
          this.workflow_services = response.workflow_services;
          this.workflow_data =  (Array.isArray(response.workflow_data) && response.workflow_data.length > 0) ? response.workflow_data[0] : {};
          this.total_costs = response.total_cost;
          this.terms_and_conditions_document = response.terms_and_conditions_document;
        },
        error: () => {
          Swal.close();
        }
      });
    },

    confirm_workflow() {
      const TOKEN = localStorage.getItem('token');
      const CONFIRM_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/confirm_workflow_api/${this.workflow_id}`;

      const FORM_DATA = {
        confirm_workflow: true
      };

      $.ajax({
        url: CONFIRM_WORKFLOW_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(FORM_DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success:(response) =>{
          Swal.close();
          
          let status = response.status;
          let message = response.message;
          if (status === 'error'){
            Swal.fire({
              text: message,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          } else if (status == 'incomplete workflow'){
            Swal.fire({
              text: message,
              icon: 'error',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$router.push({
                name: 'edit_workflow',
              });
            });
          }
          if (status === 'success') {
            Swal.fire({
              text: 'Workflow confirmed successfully, you will receive an email with your link.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$router.push({
                name: 'project_dashboard',
              });
            });
          }
        },
        error:() =>{
          Swal.close();
          Swal.fire({
            text: 'Failed to confirm workflow',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      });
    },

    back_to(){
      this.$router.push({
        name: 'reasons_for_usage',
      });
    },
  },

  mounted() {
    this.get_workflow_information();
  }
};