import NavBar from '@/components/NavBar/NavBar.vue';


export default {
  name: 'TemplateManagement',

  components: {
    NavBar
  },
  data: () => ({
    templates: [],
    template_types: [],
    workflows: [],
    template_type: '',
    uploaded_file: null,
    template_name:'',
    template_description: '',
    new_template_type: '',
    loading: false
   }),
  methods: {
    get_template_types(){
      const TOKEN = localStorage.getItem('token');
      const GET_TEMPLATE_TYPES_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/template_types_api/`;
      
      $.ajax({
        url: GET_TEMPLATE_TYPES_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.template_types = response.data;


          this.loading = false; // Set loading to false after data is loaded
        },
        error: () => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = 'Error Getting questions';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    on_file_change(event) {
      const FILE = event.target.files[0];
      const MAX_SIZE = 5 * 1024 * 1024;
      if (FILE.size > MAX_SIZE) {
        event.target.value = null;
        Swal.fire({
          text: 'File is too large.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      if (FILE.type !== "application/pdf") {
        event.target.value = null;
        Swal.fire({
          text: 'Unsupported file type.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      this.uploaded_file = FILE;
    },

    create_new_template(){
      if (!this.uploaded_file){
        Swal.fire({
          text: 'Please upload a file.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      const TOKEN = localStorage.getItem('token');
      const CREATE_TEMPLATE_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/add_template_api/`;
      let final_template_type = this.template_type;

      if (this.template_type === 'Other') {
        final_template_type = this.new_template_type;
      }
      
      const FORM_DATA = new FormData();
      FORM_DATA.append('template_name', this.template_name);
      FORM_DATA.append('template_type',final_template_type);
      FORM_DATA.append('template_description', this.template_description);
      FORM_DATA.append('template_file', this.uploaded_file);

      $.ajax({
          method: "POST",
          url: CREATE_TEMPLATE_URL,
          data: FORM_DATA,
          processData: false, 
          contentType: false,
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.fire({
              icon: 'success',
              text: response.status,
              confirmButtonText: 'OK'
            }).then(() => {
              window.location.reload();
            });
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              text: error.responseJSON.error,
              confirmButtonText: 'OK'
            });
          }
        });
    },

    get_templates() {
      const TOKEN = localStorage.getItem('token');
      const GET_TEMPLATES_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/get_template_api/`;
      
      $.ajax({
        url: GET_TEMPLATES_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.templates = response;
          this.loading = false;
          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          }); // Set loading to false after data is loaded
        },
        error: () => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = 'Error Getting questions';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    assign_to_workflow(template_id){
      const template = this.templates.find(temp => temp.id === template_id);
      if (template) {
        this.template_id = template_id;
        this.template_name = template.template_name;
        this.$nextTick(() => {
          $('#assign_template_modal').modal('show');
        });
      }
    },

    get_client_workflows() {
      const TOKEN = localStorage.getItem('token');
      const GET_WORKFLOWS_API = `${process.env.API_BASE_URL}/verify_api/template_management_api/get_workflows_api/`;
  
      $.ajax({
        url: GET_WORKFLOWS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.workflows = response || []; // Handle null or non-array responses
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          Swal.close();
        }
      });
    },

    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#templates_table')) {
          $('#templates_table').DataTable().destroy();
        }
        $('#templates_table').DataTable({
          responsive: true
        });
      });
    },

    add_template(){
      this.$nextTick(() => {
        $('#add_template_modal').modal('show');
      });
    },

    assign_workflow(workflow_id){

      const TOKEN = localStorage.getItem('token');
      const ASSIGN_TO_WORKFLOW_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/assign_template_to_workflow_api/`;
      
      const FORM_DATA = {
        workflow_id: workflow_id,
        template_id: this.template_id,

      };

      $.ajax({
          method: "POST",
          url: ASSIGN_TO_WORKFLOW_URL,
          data: JSON.stringify(FORM_DATA),
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.fire({
              icon: 'success',
              text: response.status,
              confirmButtonText: 'OK'
            }).then(() => {
              window.location.reload();
            });
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              text: error.responseJSON.error,
              confirmButtonText: 'OK'
            });
          }
        });

    }



},
mounted() {
    this.get_templates();
    this.get_template_types();
    this.get_client_workflows();
}
};