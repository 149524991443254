<div class="container-fluid">
  <div v-if="loading" class="text-center mt-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else>
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4 border-0 shadow">
          <div class="card-header bg-white border-0">
            Services In Your Project
            <button type="button" class="btn btn-dark btn-sm rounded-small shadow fw-bold  float-end text-xxs" @click="navigate_to_client_service">
              <i class="fas fa-plus"></i> Add Services
            </button>
          </div>

          <div class="card-body">
              <table id="workflow-service-table" class="table-responsive w-100">
                <thead class="bg-dark text-white">
                  <tr>
                    <th class="bg-dark text-center text-uppercase text-xxs font-weight-bolder opacity-7">ID</th>
                    <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Service Name</th>
                    <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Check</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(service, index) in services" :key="service.service_id">
                    <td class="text-center text-xxs">{{ index + 1 }}</td>
                    <td class="text-xxs">{{ service.service_name }}</td>
                    <td class="text-xxs">
                      <div class="custom-control custom-checkbox">
                        <input 
                          class="form-check-input checkbox-shadow"
                          type="checkbox" 
                          :id="'check' + index" 
                          :name="'check' + index"
                          :checked="service.is_checked"
                          @change="handle_service_check(service.id, $event.target.checked)"
                          >
                        <label :for="'check' + index" class="custom-control-label"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>      
            <div class="d-flex justify-content-end mt-0">
              <button type="button" class="btn bg-green rounded-small text-xxs fw-bold shadow" @click="route_to_ordering">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

