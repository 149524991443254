<div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card shadow border custom-border rounded-3 p-4 w-50">
      <div class="text-center mb-4">
        <h5 class="text-s fw-bolder">Anti Money Laundering</h5>
      </div>
  
      <div class="row mt-2 mb-2">
        <div class="col-md-6 text-start">
          <label class="form-label text-xs fw-bold">First Name <span class="text-danger fw-bold">*</span></label>
          <input v-model="first_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required placeholder="eg. Joe">
        </div>
        <div class="col-md-6 text-start">
          <label class="form-label text-xs fw-bold">Last Name <span class="text-danger fw-bold">*</span></label>
          <input v-model="last_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. Doe">
        </div>
      </div>
  
      <div class="row mt-2 mb-2">
        <div class="col-md-6 text-start">
          <label class="form-label text-xs fw-bold">Date of Birth <span class="text-danger fw-bold">*</span></label>
          <input v-model="date_of_birth" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required placeholder="YYyymmdd">
          <small class="form-text text-danger d-none text-xs fw-bold invalid-dob"></small>
        </div>
        <div class="col-md-6 text-start">
          <label class="form-label text-xs fw-bold">Gender <span class="text-danger fw-bold">*</span></label>
          <input v-model="gender" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="M or F">
        </div>
      </div>
  
      <div class="row mt-2 mb-2">
        <div class="col text-start">
          <label class="form-label text-xs fw-bold">Country Code <span class="text-danger fw-bold">*</span></label>
          <input v-model="country_code" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required placeholder="eg. ZAF">
        </div>
      </div>
  
      <div class="text-end">
        <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="anti_money_laundering_application_api">Submit</button>
      </div>
    </div>
  </div>
  