
import Swal from 'sweetalert2';
import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import { validate_first_name, validate_last_name, validate_email, validate_phone_number, validate_name} from '@/utils/validations.js';

export default {
  name: 'ViewClient',
  components: {
    AdminNavBar,
    AdminSidebar,
  },
  data: () => ({
    admin_data: [],
    get_user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    
  }),
  methods: {
    get_client_details(user_id) {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        this.showError('.error-message', 'Server configuration error. Please try again later.');
        return;
      }
      const GET_USER_URL = `${BASE_URL}/verify_api/verify_admin_api/view_user_api/${user_id}`;

      $.ajax({
        method: "GET",
        url: GET_USER_URL,
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading users...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
            this.get_user_id = response.user[0].id
            this.first_name = response.user[0].first_name;
            this.last_name = response.user[0].last_name;
            this.email = response.user[0].email;
            this.phone_number = '0' + response.user[0].phone_number;
            this.date_joined =  response.user[0].date_joined.split('T')[0];
        
            Swal.close();
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            text: 'Failed to load users. Please try again.',
            confirmButtonText: 'OK'
          });
        }
      });
    },
    validate_inputs() {
      if (!validate_first_name(this.first_name)) return false;
      if (!validate_last_name(this.last_name)) return false;
      if (!validate_email(this.email)) return false;
      if (!validate_phone_number(this.phone_number)) return false;
      
      return true;
    },
    validate_name(field_name) {
      this[field_name] = validate_name(this[field_name]);
    },
    edit_user(get_user_id) {
      if (!this.validate_inputs()) {
        return;
      }
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        this.showError('.error-message', 'Server configuration error. Please try again later.');
        return;
      }
      const EDIT_USER_URL = `${BASE_URL}/verify_api/verify_admin_api/edit_user_api/${get_user_id}`;
      const FORM_DATA = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone_number: this.phone_number
      };
  
      $.ajax({
        method: "POST",
        url: EDIT_USER_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading users...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            confirmButtonText: 'OK'
          });
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON.error,
            confirmButtonText: 'OK'
          });
        }
      })
    },
  },
  computed: {
    filteredData() {
      return this.data[this.currentTab];
    }
  },
  mounted() {

    const user_id= this.$route.params.user_id;
    this.get_client_details(user_id);
  }
};