export default {
    name: 'CustomerSidebar',

    methods:{
        route_to(){
            this.$router.push('/');
        },
    }
}

