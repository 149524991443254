import NavBar from '@/components/NavBar/NavBar.vue';

export default {
    name: 'ViewService',
    pageTitle: 'View Service', 
    components: {
        NavBar
    },
    data: () => ({
        service_description: {}
    }),
    methods: {
    get_service_description() {
        const service_description = localStorage.getItem('service_description');
        if (service_description) {
        this.service_description = JSON.parse(service_description);
        } else {
        console.error('No service description found in localStorage');
        }
    },
    closeService() {
        this.$router.push({ name: 'service_dashboard' });
    }
    },
    mounted() {
    this.get_service_description();
    }
  }