<div class="tabs-container mt-4">
    <div class="d-flex justify-content-center">
      <button 
        class="tab-button rounded-pill btn shadow" 
        :class="{ 'bg-green text-dark': activeTab === 'form', 'bg-white text-dark': activeTab !== 'form' }" 
        @click="showTab('form')"
      >
        Form
      </button>
      <button 
        class="tab-button rounded-pill btn shadow mx-1" 
        :class="{ 'bg-green text-dark': activeTab === 'document_form', 'bg-white text-dark': activeTab !== 'document_form' }" 
        @click="showTab('document_form')"
      >
        Document Form
      </button>
    </div>
</div>

<div v-if="loading" class="text-center mt-5">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div v-else>
    <div class="row mt-0">
        <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4">
            <div class="card-header bg-dark text-center p-2">
            <h6 class="card-heading fw-bold text-white text-s">Main Categories Form</h6>
            </div>
            <div class="card-body">
            <table id="question_table" class="table-responsive w-100">
                <thead class="bg-gray">
                <tr>
                    <th class="text-center text-uppercase text-xs font-weight-bolder opacity-7">No.</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7">Question</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7">Question Type</th>
                    <th class="text-center text-uppercase text-xs font-weight-bolder opacity-7">Assign Question</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(question, index) in questions.filter(q => q.is_active)" :key="question.id">
                    <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                    <td class="text-xs fw-bold text-start">{{ question.question }}</td>
                    <td class="text-xs fw-bold text-start">{{ question.question_type }}</td>
                    <td class="text-xs fw-bold text-start">
                      <input 
                      class="form-check-input checkbox-shadow"
                      type="checkbox" 
                      :id="'check' + index"
                      v-model="question.is_checked"
                      @click="assign_question(question.id, question.is_checked)"
                      >
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        </div>
    </div>
</div>