import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';

export default {
  name: 'ViewWorkflow',
  data: () => ({customers : []}),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  methods: {
    get_workflow_customers() {
      const TOKEN = localStorage.getItem('token');
      const GET_CUSTOMERS = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_customers_in_workflow/${this.workflow_id}`;

      $.ajax({
        url: GET_CUSTOMERS,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.customers = response.data;
          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          });
          // Map and format the response data
        },
        error: () => {
          Swal.close();
        }
      });
    },
    format_date(date) {
      return new Date(date).toLocaleDateString();
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#customers_table')) {
          $('#customers_table').DataTable().destroy();
        }
        $('#customers_table').DataTable({
          responsive: true,
        });
      });
    },
    
  },
  mounted() {
    // Initialize DataTable after the table is rendered

    this.get_workflow_customers();
  },
};
