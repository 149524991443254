export default {
    name: 'CustomerProfile',
    data: () => ({
        loading: true,
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_joined: '',
        id_number: '',
    }),
    mounted() {
        this.get_customer_information();
    },

    methods: {
        get_customer_information() {
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;
            const GET_CUSTOMER_INFO_URL = `${BASE_URL}/verify_api/customer_management_api/get_customer_profile_api/`;

            $.ajax({
                method: "GET",
                url: GET_CUSTOMER_INFO_URL,
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    const user_information = response.user_information;
                    this.first_name = user_information.first_name;
                    this.last_name = user_information.last_name;
                    this.email = user_information.email;
                    this.phone_number = user_information.phone_number;
                    this.date_joined = new Date(user_information.date_joined).toLocaleDateString();
                    this.id_number = response.id_number;
                    this.loading = false;
                },
                error: () => {
                    this.loading = false;
                }
            });
        },
    }
};

    
    
    