<div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card shadow border-2 custom-border rounded-3 p-4 w-75">
        <div class="text-center">
            <p class="text-dark fw-bold mb-2 fs-5">Facial Biometrics Verification</p>
            <small class="text-danger mb-2 fw-bold">
                <i class="fa fa-info-circle me-1"></i>
                Please make sure you have your camera enabled!
            </small>
        </div>
  
        <div class="form-group mt-3">
            <label for="id_number" class="form-label fw-bold text-xs">ID Number <span class="text-danger fw-bold">*</span></label>
            <input
                id="id_number"
                type="text"
                class="form-control text-xs rounded-small p-3 shadow-sm"
                v-model="id_number"
                placeholder="Enter an SA ID Number"
            >
            <small class="text-danger p-2 text-xxs fw-bold d-none id-message">Please fill out this field</small>
            <small class="text-danger p-2 text-xxs fw-bold d-none invalid-message"> Please enter a valid ID number</small>
        </div>
  
        <div class="row mt-4">
            <div class="col-12 col-md-6 mb-3" id="take_photo">
                <figure class="content h-75 position-relative">
                    <video ref="video" id="video" class="invert mb-2 position-relative" autoplay loop muted playsinline></video>
                    <div class="circle-overlay"></div>
                </figure>
                <button 
                    @click="take_photo" 
                    class="btn bg-green shadow-sm rounded-small text-xs w-100 fw-bold shadow"
                >
                    {{ photo_taken ? 'Retake' : 'Take Photo' }}
                </button>
            </div>
  
            <div class="col-12 col-md-6 mb-3" id="submit_photo">
                <figure class="content h-75 position-relative">
                    <input v-model="photo_src" name="src" type="text" class="d-none" />
                    <canvas ref="canvas" id="canvas" class="mb-2 position-relative"></canvas>
                </figure>
                <button 
                    @click="retake_photo" 
                    type="button" 
                    class="btn bg-green rounded-pill text-xs mb-2 w-100 fw-bold shadow d-none retake-photo"
                >
                    Retake Photo
                </button>
                <button 
                    @click="submit_photo" 
                    type="submit" 
                    class="btn bg-green shadow-sm rounded-small text-xs w-100 fw-bold shadow" 
                    :disabled="!photo_taken"
                >
                    Submit
                </button>
            </div>
        </div>
    </div>
  </div>