<template>
  <div class="app">
    <!-- Render Vue Router's <router-view> to handle dynamic component rendering -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
};
</script>