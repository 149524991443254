<div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12">
        <div class="card-body">
          <div class="card rounded-3 green-shadow border-0 mt-3 p-2">
            <div class="card-body">
              <div class="project-info">
                <div class="d-flex align-items-start">
                  <div class="icon me-3 mb-2">
                    <img src="@/assets/images/workflows_icon.png" alt="Logo" class="icon"/>
                  </div>
                  <div class="project-details">
                    <h2 class="text-s fw-bold mt-2">{{ workflow_data.workflow_name }}</h2>
                    <p class="text-s">{{ workflow_data.workflow_description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="card rounded-3 shadow mt-4 border-0">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-3">
                    <button v-if="terms_and_conditions_document" type="button" class="btn bg-green rounded-pill text-xs fw-bold" data-bs-toggle="modal" data-bs-target="#uploaded_terms_modal">View Terms and Conditions</button>
                    <button v-else type="button" class="btn bg-green rounded-pill text-xs fw-bold" data-bs-toggle="modal" data-bs-target="#termsModal">View Terms and Conditions</button>
                </div>
              <h5 class="mb-3 text-s fw-bold">Services in my workflow</h5>
              <div class="row">
                <div class="col-md-8">
                  <table class="table table-secondary table-borderless mb-0">
                    <thead>
                      <tr>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7">Service Name</th>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-end">Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="workflow_services.length === 0">
                        <td colspan="2" class="text-center">You did not select services for your workflow</td>
                      </tr>
                      <tr v-for="(service, index) in workflow_services" :key="service.id">
                        <td class="text-xs text-truncate">{{ service.service.service_name }}</td>
                        <td class="text-xs text-end">R{{ service.service.service_cost }}</td>
                      </tr>
                    </tbody>
                  </table>
  
                  <table v-if="custom_services.length > 0" class="table table-secondary table-borderless mt-0">
                    <thead>
                      <tr>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7">Custom Service Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(service, index) in custom_services" :key="service.id">
                        <td class="text-xs text-truncate">{{ service.service.service_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div class="row mt-4">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-8">
                      <p class="text-xs fw-bold">Total Per Workflow (ZAR)</p>
                    </div>
                    <div class="col-4 text-end">
                      <p class="text-xs fw-bold">R{{ total_costs }}</p>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="text-center mt-3">
                <small class="text-xxs">Invoice is generated and sent every 30 days with total usage for the period</small>
              </div>
              <div class="text-end mt-3">
                <button type="button" class="btn bg-green rounded-pill fw-bold text-xs" @click="confirm_workflow">Confirm</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  


<!-- Modal for Terms and Conditions -->
<div class="modal fade" id="termsModal" tabindex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content bg-white">
            <div class="modal-header border-bottom-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="px-3 modal-info text-xs text-darkblue fw-light">
                    <p>Introduction to Verify's Ts & Cs:</p>
                    <p>
                        Welcome to the Terms and Conditions overview for Verify. These terms govern the relationship between users and verify, 
                        ensuring compliance with legal regulations and outlining the responsibilities of all parties involved. As part of the 
                        onboarding process, we will Verify the information that you provide, ensuring the accuracy and security of our platform. 
                        By using our platform, you agree to abide by these terms, which are designed to protect your rights, ensure fair usage, 
                        and maintain the integrity of our services.
                    </p>
                    <p>Compliance with Laws:</p>
                    <p> 
                        The Client agrees to comply with all relevant laws and regulations regarding the processing of information and the use of 
                        Verify services. This includes cooperation with any investigations required by law.
                    </p> 
        
                    <p>
                        User Details and Security:
                    </p>
                    <p>
                        The Client will provide user details as requested by Verify for the purpose of creating login credentials and onboarding. 
                        Verify reserves the right to take necessary actions to preserve network security and reliability. The Client must promptly 
                        report any security breaches related to Verify services.
                    </p>
                    <p>
                        Warranties:
                    </p>
                    
                    <p>
                        Both Parties warrant their validity and authority to enter into the Agreement. Verify does not provide warranties, 
                        and is not liable for certain losses except in cases of gross negligence or wilful misconduct
                    </p>
        
                    <p>Audit Rights:</p>
        
                    <p>
                        Verify reserves the right to audit the Client's compliance with the Agreement and may request information, data, or records 
                        for this purpose. The Client must cooperate with such audits and provide additional information if required.
                    </p>
        
                    <p>Limitation of Liability:</p>
        
                    <p>
                        Verify shall not be liable for certain losses unless due to gross negligence or wilful misconduct. The Client is solely liable 
                        for any claims arising from actions taken in reliance on information provided by Verify.
                    </p>
        
                    <p>Breach:</p>
        
                    <p>
                        Either Party must remedy any breach of the Agreement within seven days of notice. The Aggrieved Party may cancel the Agreement 
                        with twenty days' written notice in case of breach.
                    </p>
        
                    <p>Applicable Law and Jurisdiction:</p>
        
                    <p>
                        The platform is governed by the laws of the Republic of South Africa, and the Parties consent to the jurisdiction of the 
                        Magistrate’s Court of South Africa for legal proceedings.
                    </p>
        
                    <p>Disputes:</p>
        
                    <p>
                        Disputes shall be settled by mediation, and if necessary, by arbitration. Both Parties agree to cooperate in good faith to 
                        resolve disputes.
                    </p>
        
                    <p>Anti-Corruption Warranty:</p>
        
                    <p>
                        Verify is committed to compliance with anti-corruption laws, and the Client acknowledges their obligations under such laws. 
                        Breach of anti-corruption laws may result in penalties and legal action.
                    </p>
        
                    <p>Protection of Personal Information:</p>
                    <p>
                        Both Parties undertake to comply with the Protection of Personal Information Act (POPI) and agree on the handling and protection 
                        of personal information exchanged under the Agreement.
                    </p>
        
                    <p>Force Majeure:</p>
                    <p>
                        Neither Party shall be liable for failure to perform obligations due to circumstances beyond their control.
                    </p>
        
        
                    <p>General:</p>
                    <p>
                        In case of conflict between the platform and the law, the law shall prevail. Any modifications to the Agreement must be in writing and 
                        signed by both Parties. Indulgences granted by either Party shall not constitute waiver of rights. Successors-in-title shall be bound 
                        by the Agreement.
                    </p>
        
                    <p>Confidentiality:</p>
                    <p>
                        Both Parties agree to keep confidential all information obtained during the term of the Agreement and for a reasonable period thereafter.
                    </p>
        
                    <p>Intellectual Property:</p>
                    <p>
                        Any intellectual property developed during the term of the Agreement shall remain the property of the respective Party, unless otherwise 
                        agreed upon in writing.
                    </p>
        
                    <p>Counterparts:</p>
                    <p>
                        The Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and 
                        the same instrument.
                    </p>
        
                    <p>Legal Fees:</p>
                    <p>
                        In the event of legal action arising from the Agreement, the prevailing Party shall be entitled to recover reasonable attorney fees 
                        and court costs.
                    </p>
        
                    <p>Conclusion:</p>
                    <p>
                        In conclusion, these Terms and Conditions form the foundation of our platform's operation, providing clarity, transparency, and legal protection 
                        for all users. By adhering to these terms, you contribute to a safe and trusted environment where everyone can benefit from our services. 
                        We appreciate your cooperation and look forward to serving you while upholding the highest standards of integrity and professionalism.
                    </p>
                </div>
            </div>
            
        </div>
    </div>
</div>

<!-- Modal for Terms and Conditions -->
<div class="modal fade" id="uploaded_terms_modal" tabindex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-m modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
                <div class="ratio ratio-1x1">
                    <iframe class="w-100 border-0" :src="terms_and_conditions_document"></iframe>
                </div>
            </div>
            
        </div>
    </div>
</div>
