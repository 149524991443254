export const validate_id = (id_input) => {
    var month = parseInt(id_input.substring(2, 4), 10) - 1;
    var day = parseInt(id_input.substring(4, 6), 10);
    var id_year = parseInt(id_input.substring(0, 2), 10);

    if (month < 0 || month > 11) {
    return false;
    }

    if (day < 1 || day > 31) {
    return false;
    }

    if (id_year < new Date().getFullYear() - 100) {
    id_year += 2000;
    } else {
    id_year += 1900;
    }

    var temp_date = new Date(id_year, month, day);
    var id_date = temp_date.getDate();
    var id_month = temp_date.getMonth();

    if (id_month !== month || id_date !== day) {
    return false;
    }

    var check_sum = 0;
    var multiplier = 1;

    for (var i = 0; i < 13; i++) {
    var digit = parseInt(id_input.charAt(i), 10);
    var temp_total = digit * multiplier;

    if (temp_total > 9) {
        temp_total = parseInt(temp_total.toString().charAt(0), 10) + parseInt(temp_total.toString().charAt(1), 10);
    }

    check_sum += temp_total;
    multiplier = (multiplier % 2 === 0) ? 1 : 2;
    }

    if ((check_sum % 10) !== 0) {
    return false;
    }

    return true;
};

export const allow_num = (event) => {
    let input_value = event.target.value;
    input_value = input_value.replace(/\D/g, '');
    return input_value;
};

export function validate_first_name(first_name) {
    if (!first_name.trim()) {
      $('.first-name-message').removeClass('d-none');
      setTimeout(() => {
        $('.first-name-message').addClass('d-none');
      }, 3000);
      return false;
    }
    return true;
  }
  
// Last name validation
export function validate_last_name(last_name) {
  if (!last_name.trim()) {
    $('.last-name-message').removeClass('d-none');
    setTimeout(() => {
      $('.last-name-message').addClass('d-none');
    }, 3000);
    return false;
  }
  return true;
}

// Email validation
export function validate_email(email) {
  if (!email.trim()) {
    $('.email-message').removeClass('d-none');
    setTimeout(() => {
      $('.email-message').addClass('d-none');
    }, 3000);
    return false;
  }
  return true;
}

// Phone number validation
export function validate_phone_number(phone_number) {
  const PHONE_PATTERN = /^\d{10}$/;
  phone_number = phone_number.replace(/\D/g, '');

  if (phone_number.length === 10 && PHONE_PATTERN.test(phone_number)) {
    return true;
  } else {
    $('.phone-number-message').removeClass('d-none');
    setTimeout(() => {
      $('.phone-number-message').addClass('d-none');
    }, 3000);
    return false;
  }
}

// Name validation (for removing invalid characters)
export function validate_name(input_value) {
  return input_value.replace(/[^a-zA-Z\s]/g, '');
}

//password match validation
export function password_match(password,confirm_password) {
  if (password !== confirm_password) {
    $('.match-password-message').removeClass('d-none');
  } else {
      $('.match-password-message').addClass('d-none');
  }
}

//email match validation
export function email_match(email,confirm_email){
  if (email !== confirm_email) {
    $('.email-match-message').removeClass('d-none');
  } else {
      $('.email-match-message').addClass('d-none');
  }
}

// checks if password field is empty
export function password_check(password){
  if (!password.trim()) {
    $('.password-message').removeClass('d-none');
    setTimeout(() => {
        $('.password-message').addClass('d-none');
    }, 3000);
  }
}

// check if confirm_password field is empty
export function confirm_password_check(confirm_password){
  if (!confirm_password.trim()) {
    $('.confirm-password-message').removeClass('d-none');
    setTimeout(() => {
        $('.confirm-password-message').addClass('d-none');
    }, 3000);
  }
}

export function validate_dynamic_inputs(dynamic_fields){
  for (const field of dynamic_fields) {
    if (!field.value.trim()) {
      this.show_error(`.${field.name}-message`, `Please fill in your ${field.placeholder}`);
    }
  }

}

export function show_error(selector, message) {
  $(selector).text(message).removeClass('d-none');
  setTimeout(() => {
    $(selector).addClass('d-none');
  }, 3000);
}
