export default {
  name: 'UploadConsent',

  data: () => ({
      file_input: null,
      uploaded_file: null
    }),
    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      }
    },

  mounted() {
    this.file_input = this.$refs.file_input;
  },

  methods: {
    browse_file() {
      this.file_input.click();
    },
    on_file_change(event) {
      const FILE = event.target.files[0];
      const MAX_SIZE = 5 * 1024 * 1024;
      if (FILE.size > MAX_SIZE) {
        event.target.value = null;
        Swal.fire({
          text: 'File is too large.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      if (FILE.type !== "application/pdf") {
        event.target.value = null;
        Swal.fire({
          text: 'Unsupported file type.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      this.uploaded_file = FILE;
    },
    upload_terms_and_conditions(){
      if (!this.uploaded_file){
        Swal.fire({
          text: 'Please upload a file.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const TERMS_AND_CONDITIONS_API_URL = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/upload_terms_and_conditions_api/${this.workflow_id}`;
    
      const FORM_DATA = new FormData();
      FORM_DATA.append('terms_and_conditions', this.uploaded_file);
    
      $.ajax({
        url: TERMS_AND_CONDITIONS_API_URL,
        method: 'POST',
        processData: false,
        contentType: false,
        headers: {
            Authorization: `Token ${TOKEN}` 
        },
        data: FORM_DATA,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          $('#terms_and_conditions').modal('hide');
          this.response = response.success;
          localStorage.setItem('user_state', 'consent_uploaded')
          this.$router.push({ name: 'reasons_for_usage' });
          
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Something went wrong, please try again",
            confirmButtonText: 'OK'
          });
        }
      })
    },
    use_verify_terms_and_conditions(){
      const TOKEN = localStorage.getItem('token');
      const USE_TERMS_AND_CONDITIONS_URL = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/use_verify_terms_and_conditions/${this.workflow_id}`;
    
      const FORM_DATA = {
        "use_terms_and_conditons_consent":true
      }
    
      $.ajax({
        url: USE_TERMS_AND_CONDITIONS_URL,
        method: 'POST',
        headers: {
            Authorization: `Token ${TOKEN}` 
        },
        data: FORM_DATA,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.response = response.success;
          localStorage.setItem('user_state', 'consent_uploaded')
          this.$router.push({ name: 'reasons_for_usage' });

        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Something went wrong, please try again",
            confirmButtonText: 'OK'
          });
        }
      })

    }
    }
  }
