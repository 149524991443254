<div class="container mt-2 mb-3">
  <div class="card rounded-3  mt-4 p-0">
    <div class="card-header bg-dark text-center p-2">
      <h6 class="card-heading fw-bold text-white text-s">Your Workflow Details</h6>
    </div>
    <div class="card-body">
      <div class="row mb-1">
        <div class="col-md-3 text-s fw-bold">
          Name:
        </div>
        <div class="col-md-9 text-muted small">
          {{ workflow_name }}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-s fw-bold">
          Description:
        </div>
        <div class="col-md-9 text-muted small">
          {{ workflow_description }}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-s fw-bold">
          Status:
        </div>
        <div class="col-md-9 small" :class="workflow_status_class">
          {{ workflow_status }}
        </div>
      </div>
    </div>
  </div>

  <div class="card rounded-3 shadow p-0 mt-4">
    <div class="card-body">
      <h6 class="mb-1 fw-bold mb-5 mt-1">Progress</h6>
      <div class="progress-container">
        <div class="progress-bar">
          <div class="progress-bar-bar"></div>
        </div>
        <ul class="multi-steps">
          <li v-for="(step, index) in steps" 
              :key="index" 
              :class="['step', step.status === 'completed' ? 'completed' : 'pending', 'mb-5']">
            <div class="step-indicator"></div>
            <div class="step-label text-dark fw-bold text-xs">
              <router-link :to="step.path" class="text-primary text-xs">
                {{ step.step_name }}
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


