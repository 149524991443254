import CustomerSidebar from '@/components/CustomerSidebar/CustomerSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';

export default {
  name: 'CustomerDashboard',
  
  components: {
    CustomerSidebar,
    NavBar
  },
};