export default {
    data: () => ({
        user_info: {}
      }),
      computed: {
        page_title() {
          switch (this.$route.name) {
            case 'project_table':
              return 'Projects';
            case 'project_dashboard':
              return 'Project Dashboard';
            case 'service_dashboard':
              return 'Services';
            case 'client_services':
              return 'Select Services';
            case 'workflow_services':
              return 'Your Services';
            case 'create_workflows':
                return 'Workflows';
            case 'question_management':
                return 'Question Management';
            case 'form_management':
                return 'Form Management';
            case 'manage_form':
              return 'Manage Form';
            case 'template_management':
              return 'Template Management';
            case 'view_service':
              return 'View Service';
            case 'reasons_for_usage':
              return 'Reasons For Usage';
            case 'arrange_workflow':
                return 'Workflows';
            case 'upload_consent':
                return 'Terms and Conditions';
            case 'confirm_workflow':
              return 'Workflow Details';
            case 'view_workflow':
              return 'View Workflow';
            case 'edit_workflow':
              return 'Edit Workflow';
            case 'instant_verify':
              return 'Instant Verify';
            case 'instant_services':
              return 'Instant Services';
            case 'services':
              return 'Your Services';
            case 'assigned_workflows':
              return 'Dashboard'
            case 'customer_profile':
              return 'Profile'
            case 'verify_logs':
              return 'Service Logs';
            case 'client_admin':
                return 'Users';
            default:
              return 'Some Page';
          }
        }
      },
      methods: {
        get_user_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_USER_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_user_api/`;
        
            $.ajax({
                url: GET_USER_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    Swal.close();
                    this.user_info = response.data;
                },
                error: () => {
                    Swal.close();
                }
            });
        },
        get_initial(name) {
            if (typeof name == 'string' && name.length > 0) {
                return name.charAt(0).toUpperCase();
            } else {
                return '';
            }
        },
        route_to(){
            this.$router.push('/');
        },
        go_back() {
          this.$router.push({ name: 'project_table' });
        },
        hide_back_icon() {
          const current_route = this.$route.name;
          const hide_on_routes = ['project_table', 'assigned_workflows','customer_profile'];
          return !hide_on_routes.includes(current_route);
        },
        hide_burger_icon() {
          const current_route = this.$route.name;
          return current_route;
        },
        toggle_sidebar() {
          this.$emit('toggle-sidebar');
        }
      },
      mounted() {
        this.get_user_information();
    }
}
