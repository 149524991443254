export default {
  name: 'ReasonsForUsage',

  data: () => ({
    reasons: [] 
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  methods: {
    // Fetch reasons from the backend
    get_reasons() {
      const TOKEN = localStorage.getItem('token');
      const GET_REASONS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_reasons_api/?workflow_id=${this.workflow_id}`;

      $.ajax({
        url: GET_REASONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          // Initialize reasons with server data
          this.reasons = response.map(reason => ({
            ...reason,
            is_selected: false // Initially, mark all reasons as not selected
          }));
          // Fetch and update selected reasons
          this.get_selected_reasons();
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to fetch reasons.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        }
      });
    },

    // Fetch the selected reasons and update the is_selected value for reasons
    get_selected_reasons() {
      const TOKEN = localStorage.getItem('token');
      const GET_SELECTED_REASONS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_selected_reasons_api/?workflow_id=${this.workflow_id}`;

      $.ajax({
        url: GET_SELECTED_REASONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading selected reasons ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          // Update reasons with the selected reasons
          response.forEach(selectedReason => {
            const reason = this.reasons.find(r => r.id === selectedReason.reason_id);
            if (reason) {
              reason.is_selected = true; // Mark the reason as selected
            }
          });
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to fetch selected reasons.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        }
      });
    },

    toggle_selection(reason) {
      const TOKEN = localStorage.getItem('token');
      const SELECT_USAGE_REASONS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/select_usage_reasons_api/${this.workflow_id}`;
    
      const original_state = reason.is_selected;
    
      $.ajax({
        url: SELECT_USAGE_REASONS_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({ reason_id: reason.id, is_selected: reason.is_selected }),
        beforeSend: () => {
        },
        success: (response) => {
          Swal.close();
          if (response.status === "success") {
            reason.is_selected = original_state;
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to update selection.',
              confirmButtonText: 'OK'
            });
          }
        },
        error: (error) => {
          let error_message = 'Failed to save selected reasons';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    // Check if at least one reason is selected
    has_selected_reason() {
      return this.reasons.some(reason => reason.is_selected);
    },

    // Navigate to the next page only if at least one reason is selected
    next() {
      if (!this.has_selected_reason()) {
        Swal.fire({
          icon: 'warning',
          title: 'No Reason Selected',
          text: 'Please select at least one reason before proceeding.',
          confirmButtonText: 'OK'
        });
        return;
      }
      this.$router.push({ name: 'confirm_workflow' });
    }
  },

  mounted() {
    this.get_reasons();
  }
};