<div class="customer-sidebar rounded-3 mt-2 shadow">
    <div class="sidebar-brand">
        <img src="@/assets/logos/new_black_logo.png" alt="Logo" class="sidebar-logo mt-0"/>
    </div>
    
    <hr class="fw-bold mb-4 mt-1 mx-4 text-dark">
    <ul class="nav flex-column me-1 text-start">
        <li class="nav-item" >
          <router-link 
          :to="{ name: 'assigned_workflows' }" 
            class="nav-link fw-bold text-dark" 
            active-class="active">
            <img src="@/assets/images/workflow-assigned.png" class="icon-size me-2" />Workflows
          </router-link>
        </li>
        <li class="nav-item" >
          <router-link 
            :to="{ name: 'customer_profile' }" 
            class="nav-link fw-bold text-dark" 
            active-class="active">
            <img src="@/assets/images/user-profile.png" class="icon-small me-2" />Profile
          </router-link>
        </li>
        </ul>

    <div class="logout mt-auto p-2">
        <a href="#" class="text-xs text-black fw-bold" @click="route_to">
        <i class="fas fa-sign-out-alt me-2 green-color"></i>Logout</a>
    </div>
</div>