<div class="text-center">
    <h3 class="text-dark fw-bold mb-3">
    <img src="@/assets/images/check.png" alt="green check" width="45%">
    </h3>
</div>

<div class="text-center mb-3">
    <h4 class="text-dark fw-bold mb-3">
        Thank You 
    </h4>
    <small class="text-secondary fw-bold">
        You have completed your {{ workflowName }} journey.  
    </small><br>
    <small class="text-secondary fw-bold">
        A representative will be with you soon.  
    </small>
</div>

<div class="text-center mb-2">
    <a class="text-primary text-xs"><router-link to="/"><i class="fa fa-sign-out me-1"></i>Logout</router-link></a>
</div>
