import Swal from 'sweetalert2';
import {show_error} from '@/utils/validations.js';

export default {

    name: 'UserRegistration',

    data() {
        return ({
            first_name: '',
            last_name: '',
            email: '',
            confirm_email: '',
            phone_number: '',
            password: '',
            confirm_password: '',
            phone_number_error: false,
            dynamic_fields: [],
        });
    },

    mounted() {
        this.get_customer_registration_form();
    },
    
    methods: {
    
        handle_register() {
            if (this.validate_inputs()) {
                const client_admin_id = sessionStorage.getItem('client_admin_id');
                if (client_admin_id) {
                    this.customer_registration();
                } else {
                    this.user_registration();
                }
            }
        },
        validate_inputs() {
            if (!this.first_name.trim()) {
                $('.first-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.first-name-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.last_name.trim()) {
                $('.last-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.last-name-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.email.trim()) {
                $('.email-message').removeClass('d-none');
                setTimeout(() => {
                    $('.email-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.confirm_email.trim()) {
                $('.confirm-email-message').removeClass('d-none');
                setTimeout(() => {
                    $('.confirm-email-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.phone_number.trim()) {
                $('.phone-number-message').removeClass('d-none');
                setTimeout(() => {
                    $('.phone-number-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.password.trim()) {
                $('.password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.password-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.confirm_password.trim()) {
                $('.confirm-password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.confirm-password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (this.password !== this.confirm_password) {
                $('.match-password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.match-password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (this.email !== this.confirm_email) {
                $('.email-match-message').removeClass('d-none');
                setTimeout(() => {
                    $('.email-match-message').addClass('d-none');
                }, 3000);
                return false;
            } 

            return true; 
        },
        
        password_match() {
            if (this.password !== this.confirm_password) {
                $('.match-password-message').removeClass('d-none');
            } else {
                $('.match-password-message').addClass('d-none');
            }
        },

        email_match() {
            if (this.email !== this.confirm_email) {
                $('.email-match-message').removeClass('d-none');
            } else {
                $('.email-match-message').addClass('d-none');
            }
        },

        validate_phone_number() {

            this.phone_number = this.phone_number.replace(/\D/g, '');
            const PHONE_PATTERN = /^\d{10}$/;

            if (this.phone_number.length === 10 && PHONE_PATTERN.test(this.phone_number)) {
                this.phone_number_error = false;
            } else {
                this.phone_number_error = true;
            }
        },

        validate_name(field_name) {
            let input_value = this[field_name];
    
            input_value = input_value.replace(/[^a-zA-Z\s]/g, '');
    
            this[field_name] = input_value;
        },

        user_registration() {
            if (!this.validate_inputs()) {
                return; 
            }

            const BASE_URL = process.env.API_BASE_URL;
            const REGISTRATION_URL = `${BASE_URL}/verify_api/system_management_api/register_api/`;
            const FORM_DATA = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                confirm_email: this.confirm_email,
                phone_number: this.phone_number,
                password: this.password,
                confirm_password: this.confirm_password
            };
    
            $.ajax({
            type: "POST",
            url: REGISTRATION_URL,
            data: JSON.stringify(FORM_DATA),
            contentType: 'application/json',
            beforeSend: () => {

                Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
                });
            },
            success: (response) => {
                Swal.close();
                localStorage.setItem('user_id', response.user_id);
                localStorage.setItem('token', response.token);
                this.$router.push('/user_otp');
            },
            error: (error) => {
                Swal.close();

                let error_message = 'Registration failed';

                if (error.responseJSON) {
                    if (error.responseJSON.email_exists_error) {
                        error_message = error.responseJSON.email_exists_error;
                        $('.email-exists-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.email-exists-message').text('').addClass('d-none');
                        }, 3000);
                    } else if (error.responseJSON.phone_number_exists_error) {
                        error_message = error.responseJSON.phone_number_exists_error;
                        $('.mobile-exists-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.mobile-exists-message').text('').addClass('d-none');
                        }, 3000);
                    } else {
                        if (error.responseJSON.email) {
                            error_message = error.responseJSON.email;
                            $('.email-error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.email-error-message').text('').addClass('d-none');
                            }, 3000);
                        } else {
                            error_message = 'An error occurred during registration.';
                            $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);

                        }
                    }
                }
            }
            });
        },

        customer_registration() {
            if (!this.validate_inputs()) {
                return; 
            }
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const BASE_URL = process.env.API_BASE_URL;
            const CUSTOMER_REGISTRATION_URL = `${BASE_URL}/verify_api/system_management_api/register_customer_api/`;
            const FORM_DATA = {
              client_admin_id : GET_CLIENT_ID,
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              phone_number: this.phone_number,
              password: this.password,
              confirm_password: this.confirm_password,
              workflow_id: this.workflow_id,
              responses: this.dynamic_fields.map(field => ({
                category_id: field.category_id,
                question_id: field.question_id,
                value: field.value
              }))
            };
      
            $.ajax({
              method: "POST",
              url: CUSTOMER_REGISTRATION_URL,
              data: JSON.stringify(FORM_DATA),
              contentType: 'application/json',
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
              },
              success: (response) => {
                Swal.close();
                localStorage.setItem('user_id', response.user_id);
                localStorage.setItem('token', response.token);
                this.$router.push('/user_otp');
              },
              error: (error) => {
                Swal.close();
                let error_message = 'Registration failed';
                if (error.responseJSON && error.responseJSON.error) {
                  error_message = error.responseJSON.error;
                } else {
                  error_message = 'An error occurred during registration.';
                }
                show_error('.error-message', error_message);
              }
            });
        },

        get_customer_registration_form() {
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const BASE_URL = process.env.API_BASE_URL;
            const GET_CUSTOMER_REGISTRATION_FORM_URL = `${BASE_URL}/verify_api/system_management_api/get_register_form_api/?client_admin_id=${GET_CLIENT_ID}`;
          
            $.ajax({
              method: "GET",
              url: GET_CUSTOMER_REGISTRATION_FORM_URL,
              contentType: 'application/json',
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
              },
              success: (response) => {
                Swal.close();
                if (response.form_questions && Array.isArray(response.form_questions)) {
                  this.dynamic_fields = response.form_questions.map(question => {
                    // Handle different types of fields based on response flags
                    return {
                      category_id: question['category_id'],
                      question_id: question['question_id'],
                      question: question['question__question'],
                      is_required: question['question__is_required'],
                      question_number: question['question__number'],
                      has_checkbox: question['question__has_checkbox'],
                      has_date: question['question__has_date'],
                      has_file: question['question__has_file'],
                      has_number: question['question__has_number'],
                      has_selection: question['question__has_selection'],
                      has_text: question['question__has_text'],
                      options: question['question__has_checkbox'] 
                               ? question['question__checkbox__option'] 
                               : question['question__has_selection'] 
                               ? question['question__selectionoption__option'] 
                               : [], // Options only relevant for checkbox or selection fields
                      value: '',  // Placeholder for storing the value
                      errorMessage: ''
                    };
                  });
                }
              },
              error: () => {
                Swal.close();
              }
            });
        }
    }
};