export default {
    name: 'ClientSidebar',

    data: () => ({
        user_info: {},
        show_project_tabs: false,
        show_project_dashboard_tabs: false,
        show_instant_verify_tabs: false
    }),
    watch: {
        $route() {
            this.update_project_tabs();
            this.update_project_dashboard_tabs();
            this.update_instant_verify_tabs();
        }
    },
    methods: {
        get_user_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_USER_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_user_api/`;

            $.ajax({
                url: GET_USER_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    Swal.close();
                    this.user_info = response.data;
                },
                error: () => {
                    Swal.close();
                }
            });
        },

        route_to(){
            this.$router.push('/');
        },
        update_project_tabs() {
            const project_routes = [
                'project_table',
                'question_management',
                'form_management',
                'template_management', 
                'manage_form']
            this.show_project_tabs = project_routes.includes(this.$route.name);
        },
        update_project_dashboard_tabs() {
            const project_dashboard_routes = [
                'project_dashboard',
                'service_dashboard',
                'edit_workflow',
                'view_workflow',
                'workflow_services',
                'client_services',
                'arrange_workflow',
                'reasons_for_usage',
                'upload_consent',
                'confirm_workflow',
                'workflow_dashboard',
                'view_customers',
                'view_service',
                'client_admin'
            ]
            this.show_project_dashboard_tabs = project_dashboard_routes.includes(this.$route.name);
        },
        update_instant_verify_tabs() {
            const instant_verify_routes = [
                'instant_verify',
                'instant_services',
                'services',
                'verify_logs'
                
            ]
            this.show_instant_verify_tabs = instant_verify_routes.includes(this.$route.name);
        },
    },
    mounted() {
        this.get_user_information();
        this.update_project_tabs();
        this.update_project_dashboard_tabs();
        this.update_instant_verify_tabs();
    }
}