export default {

    name: 'UserOtp',

    data: () => ({
        otp: '',
        dynamic_fields: [],
        show_resend_message: false,
        failed_resend_message: false
        }),
    mounted() {
        const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
        if (GET_CLIENT_ID) {
            this.get_customer_otp_form();
        }
        this.send_otp();
    },
    methods: {
        // Method to handle redirects based on registration status
        handleRegistrationRedirect(response) {
            const role = response.role.toUpperCase();
            const status = response.status;

        
            switch (role) {
                case 'CUSTOMER':
                    if (status == 'id_number_exists'){
                        this.$router.push({ name: 'assigned_workflows'});
                    } else{
                        this.$router.push({ name: 'customer_id_verification'});
                    }
                    break;
                case 'CLIENTADMIN':
                    this.handleClientRedirect(status);
                    break;
                case 'SYSTEMADMIN':
                    this.$router.push('system_admin/admin_dashboard');
                    break;
                case 'CLIENT':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('/company_pending');
            }
        },
        
        handleClientRedirect(status) {
            switch (status) {
                case 'no_profile_info':
                    this.$router.push('/id_verification');
                    break;
                case 'not_verified':
                    this.$router.push('/facial_biometrics');
                    break;
                case 'no_company_profile':
                    this.$router.push('/company_verification');
                    break;
                case 'failed_verification':
                case 'pending':
                case 'declined':
                    this.$router.push('/company_pending');
                    break;
                case 'approved':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('client/project_table');
            }
        },
        validate_input(field_name) {
            let input_value = this[field_name];
            input_value = input_value.replace(/\D/g, '');
            this[field_name] = input_value;
        },


        send_otp() {
            const TOKEN = localStorage.getItem('token');
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_otp_api/`;
            $.ajax({
                url: OTP_URL,
                method: 'GET',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: () => {
                    this.$router.push('/user_otp');
                },
                error: () => {
                    this.$router.push('/user_otp');
                }
            });
        },

        resend_otp() {
            const TOKEN = localStorage.getItem('token')
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_otp_api/`;
            $.ajax({
                url: OTP_URL,
                method: 'GET',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                    });
                },
                success: () => {
                    Swal.close();
                    this.show_resend_message = true;
                    setTimeout(() => {
                        this.show_resend_message = false;
                    }, 5000);
                    this.$router.push('/user_otp');
                },
                error: () => {
                    Swal.close();
                    this.failed_resend_message = true;
                    setTimeout(() => {
                        this.failed_resend_message = false;
                    }, 5000);
                    this.$router.push('/user_otp');
                }
            });
        },

        submit_otp() {

            if (!this.otp.trim()) {
                $('.otp-message').removeClass('d-none');
                setTimeout(() => {
                    $('.otp-message').addClass('d-none');
                }, 3000);
                return false;
            }
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_otp_api/`;
            const FORM_DATA = { 
                client_admin_id: GET_CLIENT_ID,
                otp: this.otp ,
                responses: this.dynamic_fields.map(field => ({
                category_id: field.category_id,
                question_id: field.question_id,
                value: field.value
                }))
            };

            $.ajax({
                url: OTP_URL,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify(FORM_DATA),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    localStorage.setItem('user_state', 'otp_verified');
                    const CHECK_PROCESS_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;
                    
                    $.ajax({
                        url: CHECK_PROCESS_URL,
                        method: 'GET',
                        contentType: 'application/json',
                        headers: {
                            Authorization: `Token ${TOKEN}`
                        },
                        success: (response) => {
                            this.handleRegistrationRedirect(response);
                        },
                        error: () => {
                            Swal.close();
                            let error_message = 'There was an error while redirecting';
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error_message,
                                confirmButtonText: 'OK'
                            });
                        }
                    })
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Error verifying OTP';

                    if (error.responseText.trim()) {
                        const ERROR_DATA = JSON.parse(error.responseText);
                        if (ERROR_DATA && ERROR_DATA.data) {
                            error_message = ERROR_DATA.data;
                            $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                        }
                    }
                }
            });
        },
        get_customer_otp_form(){
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;
            const GET_CUSTOMER_OTP_FORM_URL = `${BASE_URL}/verify_api/system_management_api/get_otp_form_api/?client_admin_id=${GET_CLIENT_ID}`;
          
            $.ajax({
              method: "GET",
              url: GET_CUSTOMER_OTP_FORM_URL,
              contentType: 'application/json',
              headers: {
                Authorization: `Token ${TOKEN}`
              },
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
              },
              success: (response) => {
                Swal.close();    
                if (response.form_questions && Array.isArray(response.form_questions)) {
                    this.dynamic_fields = response.form_questions.map(question => {
                      // Handle different types of fields based on response flags
                      return {
                        category_id: question['category_id'],
                        question_id: question['question_id'],
                        question: question['question__question'],
                        is_required: question['question__is_required'],
                        question_number: question['question__number'],
                        has_checkbox: question['question__has_checkbox'],
                        has_date: question['question__has_date'],
                        has_file: question['question__has_file'],
                        has_number: question['question__has_number'],
                        has_selection: question['question__has_selection'],
                        has_text: question['question__has_text'],
                        options: question['question__has_checkbox'] 
                                 ? question['question__checkbox__option'] 
                                 : question['question__has_selection'] 
                                 ? question['question__selectionoption__option'] 
                                 : [], // Options only relevant for checkbox or selection fields
                        value: '',  // Placeholder for storing the value
                        errorMessage: ''
                      };
                    });
                  }
              },
              error: () => {
                Swal.close();
              }
            });
        }
    }
};